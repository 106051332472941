import { createAsyncThunk } from "@reduxjs/toolkit"
import { PaywallBaseResponse, PaywallRequest, PaywallResponse } from "types"
import { api } from "./api"
import { resolveMessage } from "./api.error"

export interface PaywallsGetResponse {
  paywalls: {
    paywall: PaywallBaseResponse
    _links: any
  }[]
}

export interface PaywallGetResponse {
  paywall: PaywallResponse
  _links: any
}

export const addPaywall = createAsyncThunk("app/addPaywall", (
    request: { paywall: PaywallRequest }, { getState, rejectWithValue }
  ) => {
    const state: any = getState()

    const { paywallAdd } = state.paywall._links

    return api.post<PaywallGetResponse>(paywallAdd, request)

    .then(
      (x) => x.data
    )

    .catch(
      (x) => {
        let errors = ""

        if (
          "errors" in x.response.data
        ) {
          errors = resolveMessage(
            x.response.data.errors
          )
        }

        return rejectWithValue(errors)
      }
    )
  }
)

export const getPaywalls = createAsyncThunk("app/getPaywalls", (
    _1, { getState }
  ) => {
    const state: any = getState()

    const { paywallsGet } = state.paywalls._links

    return api.get<PaywallsGetResponse>(paywallsGet)

    .then(
      (x) => x.data
    )
  }
)

export const getPaywall = createAsyncThunk("app/getPaywall", (
    paywallId: string, { getState }
  ) => {
    const state: any = getState();

    const { paywallsGet } = state.paywalls._links;

    return api.get<PaywallsGetResponse>(paywallsGet, {
        params: { paywallId }
      }
    )

    .then(
      (x) => {
        if (
          ! x.data.paywalls.length
        ) {
          return null;
        }

        const paywall = x.data.paywalls[0];

        return api.get<PaywallGetResponse>(
          paywall._links.paywallGet
        )

        .then(
          (x) => x.data
        );
      }
    );
  }
)

export const refreshPaywall = createAsyncThunk("app/refreshPaywall", (
    _1, { getState }
  ) => {
    const state: any = getState()

    const { paywallGet } = state.paywall._links

    return api.get<PaywallGetResponse>(paywallGet)

    .then(
      (x) => x.data
    )
  }
)

export const modifyPaywall = createAsyncThunk("app/modifyPaywall", (
    request: { paywall: PaywallRequest }, { getState, rejectWithValue }
  ) => {
    const state: any = getState()

    const { paywallModify } = state.paywall._links

    return api.put<PaywallGetResponse>(paywallModify, request)

    .then(
      (x) => x.data
    )

    .catch(
      (x) => {
        let errors = ""

        if (
          "errors" in x.response.data
        ) {
          errors = resolveMessage(
            x.response.data.errors
          )
        }

        return rejectWithValue(errors)
      }
    )
  }
)

export const deletePaywall = createAsyncThunk("app/deletePaywall", (
    _1, { getState, rejectWithValue }
  ) => {
    const state: any = getState()

    const { paywallDelete } = state.paywall._links

    return api.delete(paywallDelete)

    .catch(
      (x) => {
        let errors = ""

        if (
          "errors" in x.response.data
        ) {
          errors = resolveMessage(
            x.response.data.errors
          )
        }

        return rejectWithValue(errors)
      }
    )
  }
)
