import { useEffect, useRef } from "react"
import { useForm } from "react-hook-form"
import { useNavigate } from "react-router-dom"
import { getConfigurationOnlinePayment, modifyConfigurationOnlinePayment } from "services/api.configurationOnlinePayment"
import { useAppDispatch, useAppSelector } from "store"
import type { ConfigurationOnlinePaymentPaynowRequest, ConfigurationOnlinePaymentPayuRequest, ConfigurationOnlinePaymentPlanetPayRequest, ConfigurationOnlinePaymentPrzelewy24Request, ConfigurationOnlinePaymentTpayRequest } from "types"
import { Button, InputWithLabel, SelectWithLabel } from "components"
import toast from "react-hot-toast"
import { UsersIcon } from "@heroicons/react/16/solid"

const providerOptions = [
  /* "Paynow", */
  /* "Payu", */
  "PlanetPay",
  /* "Przelewy24", */
  /* "Tpay" */
]

const options = [
  "Enable",
  "SandBox",
]

export const ConfigurationOnlinePaymentEditFormComponent = (
  { onSuccessSaved }: { onSuccessSaved?: () => void }
) => {
  const navigate = useNavigate()

  const dispatch = useAppDispatch()

  const serverIpAddress = useAppSelector((x) => x.server.serverIpAddress)

  const { ordersOnlinePaidStatusRaise } = useAppSelector(x => x.paywall._links)

  const {
    setValue,
    watch,
    register,
    handleSubmit,
    formState: {
      errors
    }
  } = useForm<{
      provider: string
      configurationOnlinePaymentPaynow?: ConfigurationOnlinePaymentPaynowRequest
      configurationOnlinePaymentPayu?: ConfigurationOnlinePaymentPayuRequest
      configurationOnlinePaymentPlanetPay?: ConfigurationOnlinePaymentPlanetPayRequest
      configurationOnlinePaymentPrzelewy24?: ConfigurationOnlinePaymentPrzelewy24Request
      configurationOnlinePaymentTpay?: ConfigurationOnlinePaymentTpayRequest
    }
  >()

  const provider = watch("provider")

  const configurationOnlinePaymentTpay = watch("configurationOnlinePaymentTpay")

  const configurationOnlinePaymentPrzelewy24 = watch("configurationOnlinePaymentPrzelewy24")

  const configurationOnlinePaymentPlanetPay = watch("configurationOnlinePaymentPlanetPay")

  const configurationOnlinePaymentPayu = watch("configurationOnlinePaymentPayu")

  const configurationOnlinePaymentPaynow = watch("configurationOnlinePaymentPaynow")

  const orderOnlinePaidStatusRaiseInput = useRef<HTMLInputElement>(null)

  const serverIpAddressInput = useRef<HTMLInputElement>(null)

  useEffect(
    () => {
      dispatch(
        getConfigurationOnlinePayment()
      )

      .unwrap()

      .then(
        (x) => {
          const { configurationOnlinePaymentPaynow, configurationOnlinePaymentPayu, configurationOnlinePaymentPlanetPay, configurationOnlinePaymentPrzelewy24, configurationOnlinePaymentTpay } = x

          setValue("provider", configurationOnlinePaymentPaynow ? "Paynow" : configurationOnlinePaymentPayu ? "Payu" : configurationOnlinePaymentPlanetPay ? "PlanetPay" : configurationOnlinePaymentPrzelewy24 ? "Przelewy24" : configurationOnlinePaymentTpay ? "Tpay" : "")

          setValue("configurationOnlinePaymentPaynow", configurationOnlinePaymentPaynow)

          setValue("configurationOnlinePaymentPayu", configurationOnlinePaymentPayu)

          setValue("configurationOnlinePaymentPlanetPay", configurationOnlinePaymentPlanetPay)

          setValue("configurationOnlinePaymentPrzelewy24", configurationOnlinePaymentPrzelewy24)

          setValue("configurationOnlinePaymentTpay", configurationOnlinePaymentTpay)
        }
      )
    },
      []
  )

  /**
   * Zapisuję konfigurację brami płatności
   * @param data Formularz
   */
  const onSubmit = (
    data: { provider: string; configurationOnlinePaymentPaynow?: ConfigurationOnlinePaymentPaynowRequest; configurationOnlinePaymentPayu?: ConfigurationOnlinePaymentPayuRequest; configurationOnlinePaymentPlanetPay?: ConfigurationOnlinePaymentPlanetPayRequest; configurationOnlinePaymentPrzelewy24?: ConfigurationOnlinePaymentPrzelewy24Request; configurationOnlinePaymentTpay?: ConfigurationOnlinePaymentTpayRequest }
  ) => {
    if (data.provider !== "Paynow") delete data.configurationOnlinePaymentPaynow

    if (data.provider !== "Payu") delete data.configurationOnlinePaymentPayu

    if (data.provider !== "PlanetPay") delete data.configurationOnlinePaymentPlanetPay

    if (data.provider !== "Przelewy24") delete data.configurationOnlinePaymentPrzelewy24

    if (data.provider !== "Tpay") delete data.configurationOnlinePaymentTpay

    dispatch(
      modifyConfigurationOnlinePayment(data)
    )
    .unwrap()

    .then(
      () => {
        if (onSuccessSaved) {
          onSuccessSaved();
        }

        toast.success("Konfiguracja zapisana.")
      }
    )

    .catch(
      (x) => toast.error(x)
    )
  }

  /**
   * Kopiuje do schowka adres URL
   */
  const onCopyOrderOnlinePaidStatusRaiseUrl = () => {
    orderOnlinePaidStatusRaiseInput.current?.focus();

    orderOnlinePaidStatusRaiseInput.current?.select();

    window.document.execCommand("copy");
  };

  /**
   * Kopiuje do schowka adres IP server
   */
  const onCopyServerIpAddress = () => {
    serverIpAddressInput.current?.focus();

    serverIpAddressInput.current?.select();

    window.document.execCommand("copy");
  };

  /**
   * Otwiera Paywalls
   */
  const onPaywallsOpen = () => {
    navigate("/paywalls")
  }

  const renderFields = (provider: string) => {
    switch (provider) {
      case "Paynow":
        return (
          <>
            <SelectWithLabel id="configurationOnlinePaymentPaynow.configurationMode"
              label="Tryb pracy:"
              labelFor="configurationOnlinePaymentPaynow.configurationMode"
              selected={configurationOnlinePaymentPaynow?.configurationMode ? configurationOnlinePaymentPaynow.configurationMode : null}
              options={options}
              errorMessage={errors.configurationOnlinePaymentPaynow?.configurationMode?.message}
              {...register("configurationOnlinePaymentPaynow.configurationMode", {
                  required: "Wybierz tryb pracy."
                }
              )}
              onChange={
                (xd) => setValue("configurationOnlinePaymentPaynow.configurationMode", String(xd))
              }
            />

            <InputWithLabel id="configurationOnlinePaymentPaynow.configurationApikey"
              label="API Key:"
              labelFor="configurationOnlinePaymentPaynow.configurationApikey"
              isError={!!errors.configurationOnlinePaymentPaynow?.configurationApikey}
              errorMessage={errors.configurationOnlinePaymentPaynow?.configurationApikey?.message}
              {...register("configurationOnlinePaymentPaynow.configurationApikey", {
                  required: "Uzupełnij API Key.", maxLength: { value: 4000, message: "Uzupełnij maksymalnie 4000 znakami." }
                }
              )}
            />

            <InputWithLabel id="configurationOnlinePaymentPaynow.configurationKey"
              label="Key:"
              labelFor="configurationOnlinePaymentPaynow.configurationKey"
              isError={!!errors.configurationOnlinePaymentPaynow?.configurationKey}
              errorMessage={errors.configurationOnlinePaymentPaynow?.configurationKey?.message}
              {...register("configurationOnlinePaymentPaynow.configurationKey", {
                  required: "Uzupełnij Key.", maxLength: { value: 4000, message: "Uzupełnij maksymalnie 4000 znakami." }
                }
              )}
            />
          </>
        )
      case "Payu":
        return (
          <>
            <SelectWithLabel id="configurationOnlinePaymentPayu.configurationMode"
              label="Tryb pracy:"
              labelFor="configurationOnlinePaymentPayu.configurationMode"
              selected={configurationOnlinePaymentPayu?.configurationMode ? configurationOnlinePaymentPayu.configurationMode : null}
              options={options}
              errorMessage={errors.configurationOnlinePaymentPayu?.configurationMode?.message}
              {...register("configurationOnlinePaymentPayu.configurationMode", {
                  required: "Wybierz tryb pracy."
                }
              )}
              onChange={
                (value) => setValue("configurationOnlinePaymentPayu.configurationMode", String(value))
              }
            />

            <InputWithLabel id="configurationClientId"
              label="ID klienta:"
              labelFor="configurationClientId"
              isError={!!errors.configurationOnlinePaymentPayu?.configurationClientId}
              errorMessage={errors.configurationOnlinePaymentPayu?.configurationClientId?.message}
              {...register("configurationOnlinePaymentPayu.configurationClientId", {
                  required: "Uzupełnij ID klienta.", maxLength: { value: 4000, message: "Uzupełnij maksymalnie 4000 znakami." }
                }
              )}
            />

            <InputWithLabel
              id="configurationClientSecret"
              label="Sekret:"
              labelFor="configurationClientSecret"
              isError={!!errors.configurationOnlinePaymentPayu?.configurationClientSecret}
              errorMessage={errors.configurationOnlinePaymentPayu?.configurationClientSecret?.message}
              {...register("configurationOnlinePaymentPayu.configurationClientSecret", {
                  required: "Uzupełnij sekret.", maxLength: { value: 4000, message: "Uzupełnij maksymalnie 4000 znakami." }
                }
              )}
            />
          </>
        )
      case "PlanetPay":
        return (
          <>
            <SelectWithLabel id="configurationOnlinePaymentPlanetPay.configurationMode"
              label="Tryb pracy:"
              labelFor="configurationOnlinePaymentPlanetPay.configurationMode"
              selected={configurationOnlinePaymentPlanetPay?.configurationMode ? configurationOnlinePaymentPlanetPay.configurationMode : null}
              options={options}
              errorMessage={errors.configurationOnlinePaymentPlanetPay?.configurationMode?.message}
              {...register("configurationOnlinePaymentPlanetPay.configurationMode", {
                  required: "Wybierz tryb pracy."
                }
              )}
              onChange={
                (xd) => setValue("configurationOnlinePaymentPlanetPay.configurationMode", String(xd))
              }
            />

            <InputWithLabel
              id="configurationMerchantId"
              label="ID merchanta:"
              labelFor="configurationMerchantId"
              isError={!!errors.configurationOnlinePaymentPlanetPay?.configurationMerchantId}
              errorMessage={errors.configurationOnlinePaymentPlanetPay?.configurationMerchantId?.message}
              {...register("configurationOnlinePaymentPlanetPay.configurationMerchantId", {
                  required: "Uzupełnij ID merchanta.", maxLength: { value: 4000, message: "Uzupełnij maksymalnie 4000 znakami." }
                }
              )}
            />

            <InputWithLabel
              id="configurationOnlinePaymentPlanetPay.configurationSecret"
              label="Sekret:"
              labelFor="configurationOnlinePaymentPlanetPay.configurationSecret"
              isError={!!errors.configurationOnlinePaymentPlanetPay?.configurationSecret}
              errorMessage={errors.configurationOnlinePaymentPlanetPay?.configurationSecret?.message}
              {...register("configurationOnlinePaymentPlanetPay.configurationSecret", {
                  required: "Uzupełnij sekret.", maxLength: { value: 4000, message: "Uzupełnij maksymalnie 4000 znakami." }
                }
              )}
            />

            <div>
              <label htmlFor="" className="block text-sm font-medium leading-6 text-gray-900">
                Adres URL WebHook:
              </label>
              <div className="group relative mt-2 rounded-md shadow-sm">
                <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-2">
                  <span className="text-gray-500 sm:text-sm">
                    <UsersIcon className="h-4 w-4 shrink-0" />
                  </span>
                </div>
                <input type="text" className="block w-full rounded-md border-0 py-1.5 pl-7 pr-[58px] text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  ref={orderOnlinePaidStatusRaiseInput} value={ordersOnlinePaidStatusRaise} readOnly={true}
                />
                <button className="absolute inset-y-0 right-0 flex items-center pr-3 text-gray-500 hover:text-indigo-700 sm:text-sm"
                  onClick={
                    (e) => {
                      e.preventDefault();

                      onCopyOrderOnlinePaidStatusRaiseUrl();
                    }
                  }
                >
                  Kopiuj
                </button>
              </div>
            </div>
          </>
        )
      case "Przelewy24":
        return (
          <>
            <SelectWithLabel id="configurationOnlinePaymentPrzelewy24.configurationMode"
              label="Tryb pracy:"
              labelFor="configurationOnlinePaymentPrzelewy24.configurationMode"
              selected={configurationOnlinePaymentPrzelewy24?.configurationMode ? configurationOnlinePaymentPrzelewy24.configurationMode : null}
              errorMessage={errors.configurationOnlinePaymentPrzelewy24?.configurationMode?.message}
              options={options}
              {...register("configurationOnlinePaymentPrzelewy24.configurationMode", {
                  required: "Wybierz tryb pracy."
                }
              )}
              onChange={
                (xd) => setValue("configurationOnlinePaymentPrzelewy24.configurationMode", String(xd))
            }
            />

            <InputWithLabel
              id="configurationOnlinePaymentPrzelewy24.configurationMerchantId"
              label="ID merchanta:"
              labelFor="configurationOnlinePaymentPrzelewy24.configurationMerchantId"
              isError={!!errors.configurationOnlinePaymentPrzelewy24?.configurationMerchantId}
              errorMessage={errors.configurationOnlinePaymentPrzelewy24?.configurationMerchantId?.message}
              {...register("configurationOnlinePaymentPrzelewy24.configurationMerchantId", {
                  required: "Uzupełnij ID merchanta.", maxLength: { value: 4000, message: "Uzupełnij maksymalnie 4000 znakami." }
                }
              )}
            />
            <InputWithLabel
              id="configurationOnlinePaymentPrzelewy24.configurationPosId"
              label="ID POS:"
              labelFor="configurationOnlinePaymentPrzelewy24.configurationPosId"
              isError={!!errors.configurationOnlinePaymentPrzelewy24?.configurationPosId}
              errorMessage={errors.configurationOnlinePaymentPrzelewy24?.configurationPosId?.message}
              {...register("configurationOnlinePaymentPrzelewy24.configurationPosId", {
                  required: "Uzupełnij sekret.", maxLength: { value: 4000, message: "Uzupełnij maksymalnie 4000 znakami." }
                }
              )}
            />
            <InputWithLabel
              id="configurationOnlinePaymentPrzelewy24.configurationCrc"
              label="CRC"
              labelFor="configurationOnlinePaymentPrzelewy24.configurationCrc"
              isError={!!errors.configurationOnlinePaymentPrzelewy24?.configurationCrc}
              errorMessage={errors.configurationOnlinePaymentPrzelewy24?.configurationCrc?.message}
              {...register("configurationOnlinePaymentPrzelewy24.configurationCrc", {
                  required: "Uzupełnij CRC.", maxLength: { value: 4000, message: "Uzupełnij maksymalnie 4000 znakami." }
                }
              )}
            />
            <InputWithLabel
              id="configurationOnlinePaymentPrzelewy24.configurationPrivateApiKey"
              label="Klucz API"
              labelFor="configurationOnlinePaymentPrzelewy24.configurationPrivateApiKey"
              isError={!!errors.configurationOnlinePaymentPrzelewy24?.configurationPrivateApiKey}
              errorMessage={errors.configurationOnlinePaymentPrzelewy24?.configurationPrivateApiKey?.message}
              {...register("configurationOnlinePaymentPrzelewy24.configurationPrivateApiKey", {
                  required: "Uzupełnij klucz API.", maxLength: { value: 4000, message: "Uzupełnij maksymalnie 4000 znakami." }
                }
              )}
            />

            {serverIpAddress ? (<>
              <div>
                <label htmlFor="" className="block text-sm font-medium leading-6 text-gray-900">
                  Adres IP formularza płatności:
                </label>
                <div className="group relative mt-2 rounded-md shadow-sm">
                  <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-2">
                    <span className="text-gray-500 sm:text-sm">
                      <UsersIcon className="h-4 w-4 shrink-0" />
                    </span>
                  </div>
                  <input type="text" className="block w-full rounded-md border-0 py-1.5 pl-7 pr-[58px] text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    ref={serverIpAddressInput} value={serverIpAddress?.serverIpAddress} readOnly={true}
                  />
                  <button className="absolute inset-y-0 right-0 flex items-center pr-3 text-gray-500 hover:text-indigo-700 sm:text-sm"
                    onClick={
                      (e) => {
                        e.preventDefault();

                        onCopyServerIpAddress();
                      }
                    }
                  >
                    Kopiuj
                  </button>
                </div>
              </div>
            </>) : null}
          </>
        )
      case "Tpay":
        return (
          <>
            <SelectWithLabel id="configurationOnlinePaymentTpay.configurationMode"
              label="Tryb pracy:"
              labelFor="configurationOnlinePaymentTpay.configurationMode"
              selected={configurationOnlinePaymentTpay?.configurationMode ? configurationOnlinePaymentTpay.configurationMode : null}
              options={options}
              errorMessage={errors.configurationOnlinePaymentTpay?.configurationMode?.message}
              {...register("configurationOnlinePaymentTpay.configurationMode", {
                  required: "Wybierz tryb pracy."
                }
              )}
              onChange={
                (value) => setValue("configurationOnlinePaymentTpay.configurationMode", String(value))
              }
            />

            <InputWithLabel
              id="configurationOnlinePaymentTpay.configurationClientId"
              label="ID klienta:"
              labelFor="configurationOnlinePaymentTpay.configurationClientId"
              isError={!!errors.configurationOnlinePaymentTpay?.configurationClientId}
              errorMessage={errors.configurationOnlinePaymentTpay?.configurationClientId?.message}
              {...register("configurationOnlinePaymentTpay.configurationClientId", {
                  required: "Uzupełnij ID klienta.", maxLength: { value: 4000, message: "Uzupełnij maksymalnie 4000 znakami." }
                }
              )}
            />

            <InputWithLabel
              id="configurationOnlinePaymentTpay.configurationClientSecret"
              label="Sekret:"
              labelFor="configurationOnlinePaymentTpay.configurationClientSecret"
              isError={!!errors.configurationOnlinePaymentTpay?.configurationClientSecret}
              errorMessage={errors.configurationOnlinePaymentTpay?.configurationClientSecret?.message}
              {...register("configurationOnlinePaymentTpay.configurationClientSecret", {
                  required: "Uzupełnij sekret.", maxLength: { value: 4000, message: "Uzupełnij maksymalnie 4000 znakami." }
                }
              )}
            />
          </>
        )
      default:
        return null
    }
  }

  return (
    <>
      <form className="flex flex-col gap-y-2"
        onSubmit={
          handleSubmit(
            (x) => onSubmit(x)
          )
        }
      >
        <SelectWithLabel id="provider"
          label="Operator płatności"
          labelFor="provider"
          selected={provider}
          options={providerOptions}
          errorMessage={errors.provider?.message}
          {...register("provider", {
              required: "Wybierz operatora płatności."
            }
          )}
          onChange={
            (provider) => setValue("provider", String(provider))
          }
        />

        {renderFields(provider)}

        <div className="mt-4 flex items-center justify-end gap-x-6">
          <Button
            variant="empty"
            content="Anuluj"
            onClick={() => {
              onPaywallsOpen()
            }}
          />
          <Button variant="filled" content="Zapisz" />
        </div>
      </form>
    </>
  )
}
