import { useEffect, useState } from "react"
import { useForm } from "react-hook-form"
import { useNavigate } from "react-router-dom"
import { getInvoicePaymentMethods, getPaymentMethod, modifyPaymentMethod } from "services/api.paymentMethod"
import { useAppDispatch } from "store"
import type { PaymentMethodRequest } from "types"
import { Button, InputWithLabel, SelectWithLabel } from "components"
import toast from "react-hot-toast"

export const PaymentMethodEditFormComponent = (
  { onSuccessSaved }: { onSuccessSaved?: () => void }
) => {
  const navigate = useNavigate()

  const dispatch = useAppDispatch()

  const [paymentMethods, setPaymentMethods] = useState<PaymentMethodRequest[]>([])

  const {
    watch,
    register,
    handleSubmit,
    setValue,
    formState: {
      errors
    }
  } = useForm<{
    paymentMethodName: string | null
    paymentMethodTokenizer: boolean | null
  }>()

  const paymentMethodName = watch("paymentMethodName")

  const paymentMethodOptions = paymentMethods.map(
    (payment) => payment.paymentMethodName
  )

  useEffect(
    () => {
      Promise.all([
          dispatch(
            getInvoicePaymentMethods()
          )

          .unwrap(),

          dispatch(
            getPaymentMethod()
          )
          
          .unwrap()
        ]
      )

      .then(
        ([
          paymentMethods,
          paymentMethod
        ]) => {
          if (
            paymentMethod?.cardPaymentMethod
          ) {
            setValue(
              "paymentMethodName", paymentMethod?.cardPaymentMethod?.paymentMethodName
            )
          } else if (
            paymentMethod?.onlinePaymentMethod
          ) {
            setValue(
              "paymentMethodName", paymentMethod?.onlinePaymentMethod?.paymentMethodName
            )
          }

          if (
            paymentMethod?.cardPaymentMethod
          ) {
            setValue("paymentMethodTokenizer", true)
          }

          setPaymentMethods(
            paymentMethods.paymentMethods
          )
        }
      )
    },
      []
  )

  /**
   * Zapisuje metodę płatności
   * @param data Formularz
   */
  const onSubmit = (
    data: { paymentMethodName: string | null; paymentMethodTokenizer: boolean | null }
  ) => {
    let paymentMethod = paymentMethods.find(
      (y) => y.paymentMethodName === data.paymentMethodName
    )

    if (! paymentMethod) {
      paymentMethod = { paymentMethodName: data.paymentMethodName ! }
    }

    dispatch(
      modifyPaymentMethod({
          cardPaymentMethod: data.paymentMethodTokenizer ? paymentMethod : undefined,
          onlinePaymentMethod: !data.paymentMethodTokenizer ? paymentMethod : undefined,
        }
      )
    )

    .unwrap()

    .then(
      () => {
        if (onSuccessSaved) {
          onSuccessSaved();
        }

        toast.success("Metoda płatność zapisana.")
      }
    )

    .catch(
      (x) => toast.error(x)
    )
  }

  /**
   * Otwiera Paywalls
   */
  const onPaywallsOpen = () => {
    navigate("/paywalls")
  }

  return (
    <>
      <form
        className="flex
          flex-col
          gap-y-2"
        onSubmit={
          handleSubmit(
            (x) => onSubmit(x)
          )
        }
      >
        {paymentMethods.length ? (
          <SelectWithLabel id="paymentMethodName"
            label="Wybierz metodę płatności z listy:"
            labelFor="paymentMethodName"
            selected={paymentMethodName}
            options={paymentMethodOptions}
            errorMessage={errors.paymentMethodName?.message}
            {...register("paymentMethodName", {
                required: "Wybierz metodę płatności."
              }
            )}
            onChange={
              (value) => setValue("paymentMethodName", String(value))
            }
          />
        ) : (
          <InputWithLabel
            id="paymentMethodName"
            label="Nazwa metody płatności:"
            labelFor="paymentMethodName"
            {...register("paymentMethodName", {
                required: "Uzupełnij nazwę.", maxLength: { value: 4000, message: "Uzupełnij nazwę maksymalnie 4000 znakami." }
              }
            )}
          />
        )}

        <div className="relative flex items-start">
          <div className="flex h-6 items-center">
            <input id="paymentMethodTokenizer" type="checkbox" {...register("paymentMethodTokenizer")} className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600" />
          </div>
          <label htmlFor="paymentMethodTokenizer" className="ml-3 text-sm font-medium leading-6 text-gray-900">
            Włącz tokenizację karty
          </label>
        </div>

        <div className="mt-4 flex items-center justify-end gap-x-6">
          <Button
            variant="empty"
            content="Anuluj"
            onClick={
              () => onPaywallsOpen()
            }
          />

          <Button variant="filled" content="Zapisz" />
        </div>
      </form>
    </>
  )
}
