type PaywallsGridItemProps = {
  openMethod: () => void
  imageUrl: string
  paywallName: string
  productName: string
  paymentMethodName: string
}

export const PaywallsGridItem = ({
  openMethod,
  imageUrl,
  paywallName,
  productName,
  paymentMethodName
}: PaywallsGridItemProps) => {
  return (
    <li className="overflow-hidden rounded-xl border border-gray-200">
      <a href="#" onClick={openMethod}>
        <div className="flex items-center gap-x-4 border-b border-gray-900/5 bg-gray-50 p-6">
          {imageUrl ? (
            <img
              src={imageUrl}
              alt={paywallName}
              className="h-12 w-12 flex-none rounded-lg bg-white object-cover ring-1 ring-gray-900/10"
            />
          ) : null}
          <div className="text-sm font-medium leading-6 text-gray-900">{paywallName}</div>
        </div>
        <dl className="-my-3 divide-y divide-gray-100 px-6 py-4 text-sm leading-6">
          <div className="flex justify-between gap-x-4 py-3">
            <dt className="text-gray-500">Nazwa produktu:</dt>
            <dd className="text-gray-700">
              <div className="font-medium text-gray-900">{productName}</div>
            </dd>
          </div>
          <div className="flex justify-between gap-x-4 py-3">
            <dt className="text-gray-500">Metoda płatności:</dt>
            <dd className="flex items-start gap-x-2">
              <div className="font-medium text-gray-900">{paymentMethodName}</div>
            </dd>
          </div>
        </dl>
      </a>
    </li>
  )
}
