import { createSlice } from "@reduxjs/toolkit"
import { getIndex } from "services/api.index"
import { getServerIpAddress } from "services/api.server"
import { ServerIpAddressResponse } from "types"

interface ServerState {
  _links: any
  serverIpAddress?: ServerIpAddressResponse
}

const initialState: ServerState = {
  _links: {}
}

const serverSlice = createSlice({
    name: "server",

    initialState: initialState,

    reducers: {
      //
    },

    extraReducers: (builder) =>
      builder
        .addCase(getIndex.fulfilled,
          (state, action) => {
            const { serverIpAddressGet } = action.payload._links

            state._links = { ...state._links, serverIpAddressGet }
          }
        )

        .addCase(getServerIpAddress.fulfilled,
          (state, action) => {
            state.serverIpAddress = action.payload?.serverIpAddress;
          }
        )
  }
)

export const { reducer: serverReducer } = serverSlice
