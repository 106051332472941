import { createAsyncThunk } from "@reduxjs/toolkit"
import { UserContactEmailAddressRequest, UserCredentialRequest, UserRequest } from "types"
import { api } from "./api"
import { resolveMessage } from "./api.error"

export const raiseUser = createAsyncThunk("app/raiseUser", (
    _1, { getState, rejectWithValue }
  ) => {
    const state: any = getState()

    const { userRaise } = state.user._links

    return api.post(userRaise)

    .catch(
      (x) => {
        let errors = ""

        if (
          "errors" in x.response.data
        ) {
          errors = resolveMessage(
            x.response.data.errors
          )
        }

        return rejectWithValue(errors)
      }
    )
  }
)

export const addUser = createAsyncThunk("app/addUser", (
    request: { user: UserRequest }, { getState, rejectWithValue }
  ) => {
    const state: any = getState()

    const { userAdd } = state.user._links

    return api.post(userAdd, request)

    .catch(
      (x) => {
        let errors = ""

        if (
          "errors" in x.response.data
        ) {
          errors = resolveMessage(
            x.response.data.errors
          )
        }

        return rejectWithValue(errors)
      }
    )
  }
)

export const modifyUserPassword = createAsyncThunk("app/modifyUserPassword", (
    request: { user: { userOldPassword: string; userNewPassword: string; } }, { getState, rejectWithValue }
  ) => {
    const state: any = getState()

    const { userPasswordModify } = state.user._links

    return api.put(userPasswordModify, request)

    .catch(
      (x) => {
        let errors = ""

        if (
          "errors" in x.response.data
        ) {
          errors = resolveMessage(
            x.response.data.errors
          )
        }

        return rejectWithValue(errors)
      }
    )
  }
)

export const raiseUserPassword2 = createAsyncThunk("app/raiseUserPassword2", (
    request: { user: UserContactEmailAddressRequest }, { getState, rejectWithValue }
  ) => {
    const state: any = getState()

    const { userPassword2Raise } = state.user._links

    return api.post(userPassword2Raise, request)

    .catch(
      (x) => {
        let errors = ""

        if (
          "errors" in x.response.data
        ) {
          errors = resolveMessage(
            x.response.data.errors
          )
        }

        return rejectWithValue(errors)
      }
    )
  }
)

export const signinUser = createAsyncThunk("app/signinUser", (
    request: { userCredential: UserCredentialRequest }, { getState, rejectWithValue }
  ) => {
    const state: any = getState()

    const { userSignin } = state.user._links

    return api.post(userSignin, request)

    .catch(
      (x) => {
        let errors = ""

        if (
          "errors" in x.response.data
        ) {
          errors = resolveMessage(
            x.response.data.errors
          )
        }

        return rejectWithValue(errors)
      }
    )
  }
)
