import { PayloadAction, createSlice } from "@reduxjs/toolkit"
import { PaywallResponse } from "types"
import { getIndex } from "services/api.index"
import { getPaywall, refreshPaywall } from "services/api.paywall"
import { getPaymentMethod } from "services/api.paymentMethod"
import { getStatement } from "services/api.statement"
import { getProduct } from "services/api.product"

interface PaywallState {
  isLoading: boolean
  paywall: PaywallResponse | null
  _links: any
}

const initialState: PaywallState = {
  isLoading: false,
  paywall: null,
  _links: {}
}

const paywallSlice = createSlice({
    name: "paywall",

    initialState: initialState,

    reducers: {
      setPaywall:
        /**
         * Ustawia paywall
         */
        (state, action: PayloadAction<PaywallResponse | null>) => {
          state.paywall = action.payload
        }
    },

    extraReducers: (builder) =>
      builder
        //
        // Index
        //
        .addCase(getIndex.fulfilled,
          (state, action) => {
            const { paywallAdd } = action.payload._links

            state._links = { ...state._links, paywallAdd }
          }
        )

        //
        // Paywall
        //
        .addCase(getPaywall.pending,
          (state) => {
            state.isLoading = true
          }
        )

        .addCase(getPaywall.fulfilled,
          (state, action) => {
            if (
              action.payload
            ) {
              state.paywall = action.payload.paywall

              const { paywallGet, paywallModify, paywallDelete, paywallLogoFileContentGet, fakturowniaOrderRaise, wfirmaOrderRaise, defaultOrder, ordersOnlinePaidStatusRaise } = action.payload._links

              state._links = {
                ...state._links,
                paywallGet,
                paywallModify,
                paywallDelete,
                paywallLogoFileContentGet,
                fakturowniaOrderRaise,
                wfirmaOrderRaise,
                defaultOrder,
                ordersOnlinePaidStatusRaise,
              }
            }

            state.isLoading = false
          }
        )

        .addCase(getPaywall.rejected,
          (state) => {
            state.isLoading = true
          }
        )

        .addCase(refreshPaywall.pending,
          (state) => {
            state.isLoading = true
          }
        )

        .addCase(refreshPaywall.fulfilled,
          (state, action) => {
            state.paywall = action.payload.paywall

            const { paywallGet, paywallModify, paywallDelete, paywallLogoFileContentGet } = action.payload._links

            state._links = {
              ...state._links,
              paywallGet,
              paywallModify,
              paywallDelete,
              paywallLogoFileContentGet
            }
          }
        )

        .addCase(refreshPaywall.rejected,
          (state) => {
            state.isLoading = true
          }
        )

        //
        // Payment Method
        //
        .addCase(getPaymentMethod.fulfilled,
          (state, action) => {
            if (
              state.paywall
            ) {
              state.paywall = {
                ...state.paywall,
                cardPaymentMethod: action.payload.cardPaymentMethod,
                onlinePaymentMethod: action.payload.onlinePaymentMethod
              }
            }
          }
        )

        //
        // Product
        //
        .addCase(getProduct.fulfilled,
          (state, action) => {
            if (
              state.paywall
            ) {
              state.paywall = { ...state.paywall, product: action.payload.product }
            }
          }
        )

        //
        // Statement
        //
        .addCase(getStatement.fulfilled,
          (state, action) => {
            if (
              state.paywall
            ) {
              state.paywall = { ...state.paywall, statement: action.payload.statement }
            }
          }
        )
  }
)

export const { setPaywall } = paywallSlice.actions

export const { reducer: paywallReducer } = paywallSlice
