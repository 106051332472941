import { useEffect, useRef } from "react"
import { useForm } from "react-hook-form"
import { useNavigate } from "react-router-dom"
import { getConfigurationInvoice, modifyConfigurationInvoice } from "services/api.configurationInvoice"
import { useAppDispatch, useAppSelector } from "store"
import type { ConfigurationInvoiceBillingRequest, ConfigurationInvoiceFakturowniaRequest, ConfigurationInvoiceWfirmaRequest } from "types"
import { Button, InputWithLabel, SelectWithLabel } from "components"
import toast from "react-hot-toast"
import { UsersIcon } from "@heroicons/react/16/solid"

const providerOptions = [
  /* "Billingi", */
  "Fakturownia",
  "wFirma",
]

export const ConfigurationInvoiceEditForm = (
  { onSuccessSaved }: { onSuccessSaved?: () => void }
) => {
  const navigate = useNavigate()

  const dispatch = useAppDispatch()

  const { fakturowniaOrderRaise, wfirmaOrderRaise } = useAppSelector(x => x.paywall._links)

  const {
    setValue,
    watch,
    register,
    handleSubmit,
    formState: {
      errors
    }
  } = useForm<{
      provider: string
      configurationInvoiceBillingi?: ConfigurationInvoiceBillingRequest
      configurationInvoiceFakturownia?: ConfigurationInvoiceFakturowniaRequest
      configurationInvoiceWfirma?: ConfigurationInvoiceWfirmaRequest
    }
  >()

  const provider = watch("provider")

  const configurationInvoiceBillingi = watch("configurationInvoiceBillingi")

  const configurationInvoiceFakturownia = watch("configurationInvoiceFakturownia")

  const configurationInvoiceWfirma = watch("configurationInvoiceWfirma")

  const fakturowniaOrderRaiseInput = useRef<HTMLInputElement>(null)

  const wfirmaOrderRaiseInput = useRef<HTMLInputElement>(null)

  useEffect(
    () => {
      dispatch(
        getConfigurationInvoice()
      )

      .unwrap()

      .then(
        (x) => {
          const { configurationInvoiceBillingi, configurationInvoiceFakturownia, configurationInvoiceWfirma, } = x

          setValue("provider", configurationInvoiceBillingi ? "Billingi" : configurationInvoiceFakturownia ? "Fakturownia" : configurationInvoiceWfirma ? "wFirma" : "")

          setValue("configurationInvoiceBillingi", configurationInvoiceBillingi)

          setValue("configurationInvoiceFakturownia", configurationInvoiceFakturownia)

          setValue("configurationInvoiceWfirma", configurationInvoiceWfirma)
        }
      )
    },
      []
  )

  /**
   * Zapisuje konfigurację faktur
   * @param data Formularz
   */
  const onSubmit = (
    data: { provider: string; configurationInvoiceBillingi?: ConfigurationInvoiceBillingRequest; configurationInvoiceFakturownia?: ConfigurationInvoiceFakturowniaRequest; configurationInvoiceWfirma?: ConfigurationInvoiceWfirmaRequest }
  ) => {
    if (data.provider !== "Billingi") delete data.configurationInvoiceBillingi

    if (data.provider !== "Fakturownia") delete data.configurationInvoiceFakturownia

    if (data.provider !== "wFirma") delete data.configurationInvoiceWfirma

    dispatch(
      modifyConfigurationInvoice(data)
    )

    .unwrap()

    .then(
      () => {
        if (onSuccessSaved) {
          onSuccessSaved();
        }

        toast.success("Konfiguracja zapisana.")
      }
    )

    .catch(
      (x) => toast.error(x)
    )
  }

  /**
   * Kopiuje do schowka adres URL
   */
  const onCopyFakturowniaOrderRaiseUrl = () => {
    fakturowniaOrderRaiseInput.current?.focus();

    fakturowniaOrderRaiseInput.current?.select();

    window.document.execCommand("copy");
  };

  /**
   * Kopiuje do schowka adres URL
   */
  const onCopyWfirmaOrderRaiseUrl = () => {
    wfirmaOrderRaiseInput.current?.focus();

    wfirmaOrderRaiseInput.current?.select();

    window.document.execCommand("copy");
  };

  /**
   * Otwiera Paywalls
   */
  const onPaywallsOpen = () => {
    navigate("/paywalls")
  }

  const renderProviderOptions = (provider: string) => {
    if (
      provider === "Billingi"
    ) {
      return (
        <>
          <SelectWithLabel id="configurationInvoiceBillingi.configurationEnable"
            label="Tryb pracy:"
            labelFor="configurationInvoiceBillingi.configurationEnable"
            options={["Enable"]}
            selected={configurationInvoiceBillingi?.configurationEnable ? configurationInvoiceBillingi?.configurationEnable : null}
            errorMessage={errors.configurationInvoiceBillingi?.configurationEnable?.message}
            {...register("configurationInvoiceBillingi.configurationEnable", {
                required: "Wybierz tryb pracy."
              }
            )}
            onChange={(value) => setValue("configurationInvoiceBillingi.configurationEnable", String(value))}
          />

          <InputWithLabel
            id="configurationInvoiceBillingi.configurationCertificate"
            label="Certyfikat:"
            labelFor="configurationInvoiceBillingi.configurationCertificate"
            isError={!!errors.configurationInvoiceBillingi?.configurationCertificate}
            errorMessage={errors.configurationInvoiceBillingi?.configurationCertificate?.message}
            {...register("configurationInvoiceBillingi.configurationCertificate", {
                required: "Uzupełnij certyfikat.", maxLength: { value: 4000, message: "Uzupełnij maksymalnie 4000 znakami." }
              }
            )}
          />
        </>
      )
    }

    else if (
      provider === "Fakturownia"
    ) {
      return (
        <>
          <SelectWithLabel id="configurationInvoiceFakturownia.configurationEnable"
            label="Tryb pracy:"
            labelFor="configurationInvoiceFakturownia.configurationEnable"
            options={["Enable"]}
            selected={configurationInvoiceFakturownia?.configurationEnable ? configurationInvoiceFakturownia?.configurationEnable : null}
            errorMessage={errors.configurationInvoiceFakturownia?.configurationEnable?.message}
            {...register("configurationInvoiceFakturownia.configurationEnable", {
                required: "Wybierz tryb pracy."
              }
            )}
            onChange={(value) => setValue("configurationInvoiceFakturownia.configurationEnable", String(value))}
          />

          <InputWithLabel
            id="configurationInvoiceFakturownia.configurationDomain"
            label="Domena:"
            labelFor="configurationInvoiceFakturownia.configurationDomain"
            isError={!!errors.configurationInvoiceFakturownia?.configurationDomain}
            errorMessage={errors.configurationInvoiceFakturownia?.configurationDomain?.message}
            {...register("configurationInvoiceFakturownia.configurationDomain", {
                required: "Uzupełnij nazwę domeny.", maxLength: { value: 4000, message: "Uzupełnij maksymalnie 4000 znakami." }
              }
            )}
          />

          <InputWithLabel
            id="configurationInvoiceFakturownia.configurationToken"
            label="Token:"
            labelFor="configurationInvoiceFakturownia.configurationToken"
            isError={!!errors.configurationInvoiceFakturownia?.configurationToken}
            errorMessage={errors.configurationInvoiceFakturownia?.configurationToken?.message}
            {...register("configurationInvoiceFakturownia.configurationToken", {
                required: "Uzupełnij token.", maxLength: { value: 4000, message: "Uzupełnij maksymalnie 4000 znakami." }
              }
            )}
          />

          <div>
            <label htmlFor="" className="block text-sm font-medium leading-6 text-gray-900">
              Adres URL WebHook:
            </label>
            <div className="group relative mt-2 rounded-md shadow-sm">
              <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-2">
                <span className="text-gray-500 sm:text-sm">
                  <UsersIcon className="h-4 w-4 shrink-0" />
                </span>
              </div>
              <input type="text" className="block w-full rounded-md border-0 py-1.5 pl-7 pr-[58px] text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                ref={fakturowniaOrderRaiseInput} value={fakturowniaOrderRaise} readOnly={true}
              />
              <button className="absolute inset-y-0 right-0 flex items-center pr-3 text-gray-500 hover:text-indigo-700 sm:text-sm"
                onClick={
                  (e) => {
                    e.preventDefault();

                    onCopyFakturowniaOrderRaiseUrl();
                  }
                }
              >
                Kopiuj
              </button>
            </div>
          </div>
        </>
      )
    }

    else if (
      provider === "wFirma"
    ) {
      return (
        <>
          <SelectWithLabel id="configurationInvoiceWfirma.configurationEnable"
            label="Tryb pracy:"
            labelFor="configurationInvoiceWfirma.configurationEnable"
            options={["Enable"]}
            selected={configurationInvoiceWfirma?.configurationEnable ? configurationInvoiceWfirma?.configurationEnable : null}
            errorMessage={errors.configurationInvoiceWfirma?.configurationEnable?.message}
            {...register("configurationInvoiceWfirma.configurationEnable", {
                  required: "Wybierz tryb pracy."
                }
              )
            }
            onChange={
              (value) => setValue("configurationInvoiceWfirma.configurationEnable", String(value))
            }
          />

          <InputWithLabel
            id="configurationInvoiceWfirma.configurationToken"
            label="ID klienta:"
            labelFor="configurationInvoiceWfirma.configurationCompanyId"
            isError={!!errors.configurationInvoiceWfirma?.configurationCompanyId}
            errorMessage={errors.configurationInvoiceWfirma?.configurationCompanyId?.message}
            {...register("configurationInvoiceWfirma.configurationCompanyId", {
                  required: "Uzupełnij ID klienta.", maxLength: { value: 4000, message: "Uzupełnij maksymalnie 4000 znakami." }
                }
              )
            }
          />

          <InputWithLabel
            id="configurationInvoiceWfirma.configurationConnectionAccessKey"
            label="Access Key:"
            labelFor="configurationInvoiceWfirma.configurationConnectionAccessKey"
            isError={!!errors.configurationInvoiceWfirma?.configurationConnectionAccessKey}
            errorMessage={errors.configurationInvoiceWfirma?.configurationConnectionAccessKey?.message}
            {...register("configurationInvoiceWfirma.configurationConnectionAccessKey", {
                  required: "Uzupełnij Access Key.", maxLength: { value: 4000, message: "Uzupełnij maksymalnie 4000 znakami." }
                }
              )
            }
          />

          <InputWithLabel
            id="configurationInvoiceWfirma.configurationConnectionSecretKey"
            label="Secret Key:"
            labelFor="configurationInvoiceWfirma.configurationConnectionSecretKey"
            isError={!!errors.configurationInvoiceWfirma?.configurationConnectionSecretKey}
            errorMessage={errors.configurationInvoiceWfirma?.configurationConnectionSecretKey?.message}
            {...register("configurationInvoiceWfirma.configurationConnectionSecretKey", {
                  required: "Uzupełnij Secret Key.", maxLength: { value: 4000, message: "Uzupełnij maksymalnie 4000 znakami." }
                }
              )
            }
          />

          <div>
            <label htmlFor="" className="block text-sm font-medium leading-6 text-gray-900">
              Adres URL WebHook:
            </label>
            <div className="group relative mt-2 rounded-md shadow-sm">
              <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-2">
                <span className="text-gray-500 sm:text-sm">
                  <UsersIcon className="h-4 w-4 shrink-0" />
                </span>
              </div>
              <input type="text" className="block w-full rounded-md border-0 py-1.5 pl-7 pr-[58px] text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                ref={wfirmaOrderRaiseInput} value={wfirmaOrderRaise} readOnly={true}
              />
              <button className="absolute inset-y-0 right-0 flex items-center pr-3 text-gray-500 hover:text-indigo-700 sm:text-sm"
                onClick={
                  (e) => {
                    e.preventDefault();

                    onCopyWfirmaOrderRaiseUrl();
                  }
                }
              >
                Kopiuj
              </button>
            </div>
          </div>
        </>
      )
    }

    else {
      return null
    }
  }

  return (
    <>
      <form className="flex flex-col gap-y-2"
        onSubmit={
          handleSubmit(
            (x) => onSubmit(x)
          )
        }
      >
        <SelectWithLabel id="provider"
          label="Operator faktur:"
          labelFor="provider"
          options={providerOptions}
          selected={provider ? provider : null}
          errorMessage={errors.provider?.message}
          {...register("provider", {
              required: "Wybierz operatora faktur."
            }
          )}
          onChange={
            (value) => setValue("provider", String(value))
          }
        />

        {renderProviderOptions(provider)}

        <div className="mt-4 flex items-center justify-end gap-x-6">
          <Button
            variant="empty"
            content="Anuluj"
            onClick={() => {
              onPaywallsOpen()
            }}
          />

          <Button
            variant="filled"
            content="Zapisz"
          />
        </div>
      </form>
    </>
  )
}
