import { Disclosure } from "@headlessui/react"
import { MinusSmallIcon, PlusSmallIcon } from "@heroicons/react/24/outline"
import type { ReactElement, ReactNode } from "react"
import clsx from "clsx"

type AccordionWrapperProps = {
  title: string
  icon: ReactElement
  children?: ReactNode
  handleClick?: () => void
}

export function AccordionWrapper({ title, icon, children, handleClick }: AccordionWrapperProps) {
  const renderAccordionPanel = () => {
    if (!children) return null

    return (
      <Disclosure.Panel as="dd" className="mt-2">
        {children}
      </Disclosure.Panel>
    )
  }

  return (
    <div className="bg-white" onClick={handleClick}>
      <div className="mx-auto max-w-7xl">
        <dl className="space-y-6 divide-y divide-gray-900/10">
          <Disclosure as="div" className="pt-2">
            {({ open }) => (
              <>
                <dt>
                  <Disclosure.Button
                    className={
                      clsx(
                        "flex w-full items-start justify-between gap-x-2 rounded-md px-1 py-2 text-left hover:bg-gray-50 hover:text-indigo-700",
                        open ? "bg-gray-50 text-indigo-700" : "text-gray-900"
                      )
                    }
                  >
                    <span className="flex items-center gap-x-2 text-base text-sm font-semibold leading-7">
                      {icon}
                      {title}
                    </span>
                    <span className="ml-6 flex h-7 items-center">{open ? <MinusSmallIcon className="h-6 w-6" aria-hidden="true" /> : <PlusSmallIcon className="h-6 w-6" aria-hidden="true" />}</span>
                  </Disclosure.Button>
                </dt>
                {renderAccordionPanel()}
              </>
            )}
          </Disclosure>
        </dl>
      </div>
    </div>
  )
}
