import { createSlice } from "@reduxjs/toolkit"
import { getPaywall } from "services/api.paywall"
import { getStatement } from "services/api.statement"
import { StatementResponse } from "types"

interface StatementState {
  isLoading: boolean
  statement: StatementResponse | null
  _links: any
}

const initialState: StatementState = {
  isLoading: false,
  statement: null,
  _links: {}
}

const statementSlice = createSlice({
  name: "statement",

  initialState: initialState,

  reducers: {
    //
  },

  extraReducers: (builder) =>
    builder
      //
      // Paywall
      //
      .addCase(getPaywall.fulfilled, (state, action) => {
        if (!action.payload) {
          return
        }

        const { statementModify, statementGet } = action.payload._links

        state._links = { ...state._links, statementModify, statementGet }
      })

      //
      // Statement
      //
      .addCase(getStatement.pending, (state) => {
        state.isLoading = true
      })

      .addCase(getStatement.fulfilled, (state, action) => {
        state.statement = action.payload.statement

        state.isLoading = false
      })

      .addCase(getStatement.rejected, (state) => {
        state.isLoading = false
      })
})

export const { reducer: statementReducer } = statementSlice
