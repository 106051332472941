import { jwtDecode } from "jwt-decode"
import { useAppSelector } from "store"
import { UserEditFormComponent } from "../components/UserEditForm.component"
import { Sidebar } from "modules/_Layout/components/Sidebar"
import { UserPasswordEditFormComponent } from "../components/UserPasswordEditForm.component"

export const UserPage = () => {
  const userToken = useAppSelector((state) => state.userAuthentication.token)

  const decoded: any = userToken && jwtDecode(userToken)

  const isAuthentication = decoded["http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name"]

  return (
    <div className="flex min-h-dvh w-full">
      <Sidebar />

      <div
        className="flex
          min-h-dvh
          w-full
          bg-gray-100
          p-5"
      >
        <div
          className="max-w-[920px]
            w-full
            space-y-10
            divide-y
            divide-gray-900/10"
        >
          {! isAuthentication ? (
            <div
              className="grid
                grid-cols-1
                gap-x-8
                gap-y-8
                md:grid-cols-3"
            >
              <hgroup className="px-4 sm:px-0">
                <h2
                  className="text-base
                    font-semibold
                    leading-7
                    text-gray-900"
                >
                  Utwórz użytkownika
                </h2>

                {/* <p className="mt-1 text-sm leading-6 text-gray-600">This information will be displayed publicly so be careful what you share.</p> */}
              </hgroup>

              <div
                className="bg-white
                  px-4
                  py-6
                  shadow-sm
                  ring-1
                  ring-gray-900/5
                  sm:rounded-xl
                  md:col-span-2"
                >
                <UserEditFormComponent />

                <div className="sm:col-span-1" />
              </div>
            </div>
          ) : (
            <div
              className="grid
                grid-cols-1
                gap-x-8
                gap-y-8
                md:grid-cols-3"
            >
              <hgroup className="px-4 sm:px-0">
                <h2
                  className="text-base
                    font-semibold
                    leading-7
                    text-gray-900"
                >
                  Zmień hasło
                </h2>

                {/* <p className="mt-1 text-sm leading-6 text-gray-600">This information will be displayed publicly so be careful what you share.</p> */}
              </hgroup>

              <div
                className="bg-white
                  px-4
                  py-6
                  shadow-sm
                  ring-1
                  ring-gray-900/5
                  sm:rounded-xl
                  md:col-span-2"
              >
                <UserPasswordEditFormComponent />

                <div className="sm:col-span-1" />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
