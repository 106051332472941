import { useForm } from "react-hook-form"
import { useNavigate } from "react-router-dom"
import { signinUser } from "services/api.user"
import { useAppDispatch } from "store"
import type { UserCredentialRequest } from "types"
import Logo from "assets/logo.png"
import toast from "react-hot-toast"

export const UserSigninPage = () => {
  const navigate = useNavigate()

  const dispatch = useAppDispatch()

  const {
    register,
    handleSubmit,
    formState: {
      errors
    }
  } = useForm<{
    userCredential: UserCredentialRequest
  }>()

  /**
   * Loguje użytkownika
   * @param data Formularz
   */
  const onUserSignin = (
    data: { userCredential: UserCredentialRequest }
  ) => {
    dispatch(
      signinUser({
          userCredential: data.userCredential
        }
      )
    )

    .unwrap()

    .then(
      () => navigate("/paywalls")
    )

    .catch(
      (x) => toast.error(x)
    )
  }

  /**
   * Otwiera resetowanie hasła
   */
  const onUserPassword2Open = () => {
    navigate("/user/password2")
  }

  return (
    <form
      className="flex
        min-h-dvh
        flex-1
        flex-col
        justify-center
        px-6
        py-12
        lg:px-8"
      onSubmit={handleSubmit(
          (x) => onUserSignin(x)
        )
      }
    >
      <fieldset>
        <div className="sm:mx-auto sm:w-full sm:max-w-sm">
          <img className="mx-auto h-10 w-auto" src={Logo} alt="Paywallingi" />

          <legend
            className="mt-10
              text-center
              text-2xl
              font-bold
              leading-9
              tracking-tight
              text-gray-900"
            >
            Zaloguj się
          </legend>
        </div>

        <div className="mt-10 space-y-6 sm:mx-auto sm:w-full sm:max-w-sm">
          <div>
            <label className="block text-sm font-medium leading-6 text-gray-900">
              Adres e-mail:
            </label>

            <input type={"email"}
              className="mt-2
                block
                w-full
                rounded-md
                border-0
                py-1.5
                text-gray-900
                shadow-sm
                ring-1
                ring-inset
                ring-gray-300
                placeholder:text-gray-400
                focus:ring-2
                focus:ring-inset
                focus:ring-indigo-600
                sm:text-sm
                sm:leading-6"
              {...register("userCredential.userContactEmailAddress", {
                  required: "Uzupełnij adres e-mail.", maxLength: { value: 4000, message: "Uzupełnij maksymalnie 4000 znakami." }
                }
              )}
            />

            {errors.userCredential?.userContactEmailAddress?.message && <p>{errors.userCredential?.userContactEmailAddress?.message}</p>}
          </div>

          <div>
            <div className="flex items-center justify-between">
              <label htmlFor="password" className="block text-sm font-medium leading-6 text-gray-900">
                Hasło:
              </label>

              <a
                onClick={() => {
                  onUserPassword2Open()
                }}
                className="text-sm font-semibold text-indigo-600 hover:cursor-pointer hover:text-indigo-500"
              >
                Zresetuj hasło
              </a>
            </div>

            <input id={"password"} type={"password"}
              className="mt-2
                block
                w-full
                rounded-md
                border-0
                py-1.5
                text-gray-900
                shadow-sm
                ring-1
                ring-inset
                ring-gray-300
                placeholder:text-gray-400
                focus:ring-2
                focus:ring-inset
                focus:ring-indigo-600
                sm:text-sm sm:leading-6"
              {...register("userCredential.userPassword", {
                  required: "Uzupełnij hasło.", maxLength: { value: 4000, message: "Uzupełnij maksymalnie 4000 znakami." }
                }
              )}
            />
            {errors.userCredential?.userPassword?.message && <p>{errors.userCredential?.userPassword?.message}</p>}
          </div>

          <button
            className="flex
              w-full
              justify-center
              rounded-md
              bg-indigo-600
              px-3
              py-1.5
              text-sm
              font-semibold
              leading-6
              text-white
              shadow-sm
              hover:bg-indigo-500
              focus-visible:outline
              focus-visible:outline-2
              focus-visible:outline-offset-2
              focus-visible:outline-indigo-600"
          >
            Zaloguj się
          </button>
        </div>
      </fieldset>
    </form>
  )
}
