import { createAsyncThunk } from "@reduxjs/toolkit"
import { OrderBaseResponse } from "types"
import { api } from "./api"

export interface OrdersGetResponse {
  orders: {
    order: OrderBaseResponse
    _links: any
  }[]
}

export const getOrders = createAsyncThunk("app/getOrders", (
    _1, { getState }
  ) => {
    const state: any = getState()

    const { ordersGet } = state.orders._links

    return api.get<OrdersGetResponse>(ordersGet)

    .then(
      (x) => x.data
    )
  }
)
