import { Fragment } from "react"
import { Transition } from "@headlessui/react"
import { ArrowPathIcon, CheckCircleIcon, XCircleIcon } from "@heroicons/react/24/outline"
import { XMarkIcon } from "@heroicons/react/20/solid"
import toast, { Toast, resolveValue } from "react-hot-toast"

type MessageToastProps = {
  t: Toast
}

export const MessageToast = (
  { t }: MessageToastProps
) => {
  return (
    <div aria-live="assertive"
      className="pointer-events-none
        fixed
        right-4
        top-4
        z-20
        flex
        w-full
        items-end
        px-4
        py-6
        sm:items-start
        sm:p-6"
    >
      <div
        className="flex
          w-full
          flex-col
          items-end
          space-y-4"
      >
        <Transition show={t?.visible} as={Fragment}
          enter="transform ease-out duration-300 transition"
          enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
          enterTo="translate-y-0 opacity-100 sm:translate-x-0"
          leave="transition ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div
            className="pointer-events-auto
              w-full
              max-w-sm
              overflow-hidden
              rounded-lg
              bg-white
              shadow-lg
              ring-1
              ring-black
              ring-opacity-5"
          >
            <div className="p-4">
              <div className="flex items-start">
                <div className="flex-shrink-0">
                  {t.type === "success" ? (<CheckCircleIcon className="h-6 w-6 text-green-400" aria-hidden="true" />) :
                  t.type === "error" ? (<XCircleIcon className="h-6 w-6 text-red-400" aria-hidden="true" />) :
                  t.type === "loading" ? (<ArrowPathIcon className="h-6 w-6 text-gray-400" aria-hidden="true" />) : null}
                </div>

                <div className="ml-3 w-0 flex-1 pt-0.5">
                  {t.type !== "blank" && t.type !== "custom" ? (
                    <p className="text-sm font-medium text-gray-900">
                      {t.type === "success" ? "Sukces." :
                      t.type === "error" ? "Błąd." :
                      t.type === "loading" ? "Wczytuję." : null}
                    </p>
                  ) : null}

                  <p className="mt-1 text-sm text-gray-500">
                    {resolveValue(t.message, t)}
                  </p>
                </div>

                <div className="ml-4 flex flex-shrink-0">
                  <button type="button"
                    className="inline-flex
                      rounded-md
                      bg-white
                      text-gray-400
                      hover:text-gray-500
                      focus:outline-none
                      focus:ring-2
                      focus:ring-indigo-500
                      focus:ring-offset-2"
                    onClick={
                      () => toast.dismiss(t.id)
                    }
                  >
                    <span className="sr-only">Close</span>

                    <XMarkIcon className="h-5 w-5" aria-hidden="true" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Transition>
      </div>
    </div>
  )
}
