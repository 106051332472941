import { createSlice } from "@reduxjs/toolkit"
import { PaywallBaseResponse } from "types"
import { getPaywalls } from "services/api.paywall"
import { getIndex } from "services/api.index"

interface PaywallsState {
  isLoading: boolean
  paywalls: {
    paywall: PaywallBaseResponse
    _links: any
  }[]
  _links: any
}

const initialState: PaywallsState = {
  isLoading: false,
  paywalls: [],
  _links: {}
}

const paywallsSlice = createSlice({
  name: "paywalls",

  initialState: initialState,

  reducers: {
    //
  },

  extraReducers: (builder) =>
    builder
      //
      // Index
      //
      .addCase(getIndex.fulfilled, (state, action) => {
        const { paywallsGet } = action.payload._links

        state._links = { ...state._links, paywallsGet }
      })

      //
      // Paywalls
      //
      .addCase(getPaywalls.pending, (state) => {
        state.isLoading = true
      })

      .addCase(getPaywalls.fulfilled, (state, action) => {
        state.paywalls = action.payload.paywalls

        state.isLoading = false
      })

      .addCase(getPaywalls.rejected, (state) => {
        state.isLoading = false
      })
})

export const { reducer: paywallsReducer } = paywallsSlice
