import { useForm } from "react-hook-form"
import { useNavigate } from "react-router-dom"
import { raiseUserPassword2 } from "services/api.user"
import { useAppDispatch } from "store"
import type { UserContactEmailAddressRequest } from "types"
import toast from "react-hot-toast"
import Logo from "assets/logo.png"

export const UserPassword2Page = () => {
  const navigate = useNavigate()

  const dispatch = useAppDispatch()

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm<{
    user: UserContactEmailAddressRequest
  }>()

  /**
   * Podnosi hasło 2 w użytkowniku
   * @param data
   */
  const onUserPassword2Raise = (data: { user: UserContactEmailAddressRequest }) => {
    dispatch(
      raiseUserPassword2({
          user: data.user
        }
      )
    )

    .unwrap()

    .then(
      () => navigate("/user/signin")
    )

    .catch(
      (x) => toast.error(x)
    )
  }

  return (
    <>
      <form
        onSubmit={handleSubmit((x) => {
          onUserPassword2Raise(x)
        })}
        className="flex min-h-dvh flex-1 flex-col justify-center px-6 py-12 lg:px-8"
      >
        <fieldset>
          <div className="sm:mx-auto sm:w-full sm:max-w-sm">
            <img className="mx-auto h-10 w-auto" src={Logo} alt="Paywallingi" />
            <legend className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">Zresetuj hasło</legend>
          </div>
          <div className="mt-10 space-y-6 sm:mx-auto sm:w-full sm:max-w-sm">
            <div>
              <label className="block text-sm font-medium leading-6 text-gray-900">
                Adres e-mail:
                <input
                  type={"email"}
                  {...register("user.userContactEmailAddress", {
                    required: "Uzupełnij adres e-mail.",
                    maxLength: {
                      value: 4000,
                      message: "Uzupełnij maksymalnie 4000 znakami."
                    }
                  })}
                  className="mt-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </label>
              {errors.user?.userContactEmailAddress?.message && <p>{errors.user?.userContactEmailAddress?.message}</p>}
            </div>
            <button className="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">Zresetuj hasło</button>
          </div>
        </fieldset>
      </form>
    </>
  )
}
