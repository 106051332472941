import { createAsyncThunk } from "@reduxjs/toolkit"
import { PaymentMethodRequest } from "types"
import { api } from "./api"
import { resolveMessage } from "./api.error"

export interface PaymentMethodsGetResponse {
  paymentMethods: PaymentMethodRequest[]
}

export interface PaymentMethodGetResponse {
  cardPaymentMethod?: PaymentMethodRequest
  onlinePaymentMethod?: PaymentMethodRequest
}

export const getInvoicePaymentMethods = createAsyncThunk("app/getInvoicePaymentMethods", (
    _1, { getState }
  ) => {
    const state: any = getState()

    const { invoicePaymentMethodsGet } = state.paymentMethod._links

    return api.get<PaymentMethodsGetResponse>(invoicePaymentMethodsGet)

    .then(
      (x) => x.data
    )
  }
)

export const modifyPaymentMethod = createAsyncThunk("app/modifyPaymentMethod", (
    request: { onlinePaymentMethod?: PaymentMethodRequest; cardPaymentMethod?: PaymentMethodRequest }, { getState, rejectWithValue }
  ) => {
    const state: any = getState()

    const { paymentMethodModify } = state.paymentMethod._links

    return api.put(paymentMethodModify, request)

    .catch(
      (x) => {
        let errors = ""

        if (
          "errors" in x.response.data
        ) {
          errors = resolveMessage(
            x.response.data.errors
          )
        }

        return rejectWithValue(errors)
      }
    )
  }
)

export const getPaymentMethod = createAsyncThunk("app/getPaymentMethod", (
    _1, { getState }
  ) => {
    const state: any = getState()

    const { paymentMethodGet } = state.paymentMethod._links

    return api.get<PaymentMethodGetResponse>(paymentMethodGet)

    .then(
      (x) => x.data
    )
  }
)
