import axios from "axios"
import { AppStore } from "store"
import { setToken } from "modules/User/UserAuthentication.slice"

export const api = axios.create({
    baseURL: process.env.REACT_APP_API,

    validateStatus: (status) => {
      return (status >= 200 && status < 400) || status === 404
    }
  }
)

export const setupAxiosClient = (store: AppStore) => {
  api.interceptors.request.use(
    (config) => {
      const state = store.getState()

      const token = state.userAuthentication.token

      if (token && config.headers) {
        config.headers.authorization = `Bearer ${token}`
      }

      return config
    }
  )

  api.interceptors.response.use(
    (config) => {
      const auth = config.headers.authorization

      if (auth) {
        store.dispatch(setToken(auth.replace("Bearer ", "")))
      }

      return config
    }
  )
}
