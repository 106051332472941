import { createAsyncThunk } from "@reduxjs/toolkit"
import { ProductRequest } from "types"
import { api } from "./api"
import { resolveMessage } from "./api.error"

export interface ProductsGetResponse {
  products: ProductRequest[]
}

export interface ProductGetResponse {
  product?: ProductRequest
  _links: any
}

export const getInvoiceProducts = createAsyncThunk("app/getInvoiceProducts", (
    _1, { getState }
  ) => {
    const state: any = getState()

    const { invoiceProductsGet } = state.product._links

    return api.get<ProductsGetResponse>(invoiceProductsGet)

    .then(
      (x) => x.data
    )
  }
)

export const modifyProduct = createAsyncThunk("app/modifyPaymentMethod", (
    request: { product?: ProductRequest }, { getState, rejectWithValue }
  ) => {
    const state: any = getState()

    const { productModify } = state.product._links

    return api.put(productModify, request)

    .catch(
      (x) => {
        let errors = ""

        if (
          "errors" in x.response.data
        ) {
          errors = resolveMessage(
            x.response.data.errors
          )
        }

        return rejectWithValue(errors)
      }
    )
  }
)

export const getProduct = createAsyncThunk("app/getProduct", (
    _1, { getState }
  ) => {
    const state: any = getState()

    const { productGet } = state.product._links

    return api.get<ProductGetResponse>(productGet)

    .then(
      (x) => x.data
    )
  }
)
