import { createSlice } from "@reduxjs/toolkit"
import { ConfigurationInvoiceBillingRequest, ConfigurationInvoiceFakturowniaRequest, ConfigurationInvoiceWfirmaRequest } from "types"
import { getConfigurationInvoice } from "services/api.configurationInvoice"
import { getPaywall } from "services/api.paywall"

interface ConfigurationInvoiceState {
  isLoading: boolean
  configurationInvoiceBillingi?: ConfigurationInvoiceBillingRequest
  configurationInvoiceFakturownia?: ConfigurationInvoiceFakturowniaRequest
  configurationInvoiceWfirma?: ConfigurationInvoiceWfirmaRequest
  _links: any
}

const initialState: ConfigurationInvoiceState = {
  isLoading: false,
  _links: {}
}

const configurationInvoiceSlice = createSlice({
  name: "configurationInvoice",

  initialState: initialState,

  reducers: {
    //
  },

  extraReducers: (builder) =>
    builder
      //
      // Paywall
      //
      .addCase(getPaywall.fulfilled,
        (state, action) => {
          if (!action.payload) {
            return
          }

          const { configurationInvoiceModify, configurationInvoiceGet } = action.payload._links

          state._links = { ...state._links, configurationInvoiceModify, configurationInvoiceGet }
        }
      )

      //
      // Configuration Invoice
      //
      .addCase(getConfigurationInvoice.pending,
        (state) => {
          state.isLoading = true
        }
      )

      .addCase(getConfigurationInvoice.fulfilled,
        (state, action) => {
          state.configurationInvoiceBillingi = action.payload.configurationInvoiceBillingi
          state.configurationInvoiceFakturownia = action.payload.configurationInvoiceFakturownia
          state.configurationInvoiceWfirma = action.payload.configurationInvoiceWfirma

          state.isLoading = false
        }
      )

      .addCase(getConfigurationInvoice.rejected,
        (state) => {
          state.isLoading = false
        }
      )
})

export const { reducer: configurationInvoiceReducer } = configurationInvoiceSlice
