import { createAsyncThunk } from "@reduxjs/toolkit";
import { InstanceRequest, InstanceResponse } from "types";
import { api } from "./api";
import { resolveMessage } from "./api.error"

export const addInstance = createAsyncThunk("app/addInstance", (
    request: { instance: InstanceRequest }, { getState, rejectWithValue }
  ) => {
    const state: any = getState()

    const { instanceModify } = state.instance._links

    return api.put<{ instance: InstanceResponse }>(instanceModify, request)

    .then(
      (x) => x.data
    )

    .catch(
      (x) => {
        let errors = ""

        if (
          "errors" in x.response.data
        ) {
          errors = resolveMessage(
            x.response.data.errors
          )
        }

        return rejectWithValue(errors)
      }
    )
  }
)

export const getInstance = createAsyncThunk("app/getInstance", (
    _1, { getState }
  ) => {
    const state: any = getState()

    const { instanceGet } = state.instance._links

    return api.get<{ instance: InstanceResponse }>(instanceGet)

    .then(
      (x) => x.data
    )
  }
)
