import { createAsyncThunk } from "@reduxjs/toolkit"
import { api } from "./api"

export interface IndexGetResponse {
  _links: any
}

export const getIndex = createAsyncThunk("app/getIndex", (
    _1, { getState }
  ) => {
    const state: any = getState()

    const { indexGet } = state.index._links

    return api.get<IndexGetResponse>(indexGet)

    .then(
      (x) => x.data
    )
  }
)
