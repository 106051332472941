import clsx from "clsx"
import { ButtonHTMLAttributes } from "react"

type ButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & {
  variant: "empty" | "filled"
  content: string
}

export const Button = ({ variant, content, ...rest }: ButtonProps) => {
  const buttonClassess = clsx(variant === "empty" ? "text-sm font-semibold leading-6 text-gray-900" : "rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600")

  return (
    <button className={buttonClassess} {...rest}>
      {content}
    </button>
  )
}
