import { useEffect, useState } from "react"
import { useForm } from "react-hook-form"
import { useNavigate } from "react-router-dom"
import { getInvoiceProducts, getProduct, modifyProduct } from "services/api.product"
import { useAppDispatch } from "store"
import type { ProductRequest } from "types"
import { Button, InputWithLabel, SelectWithLabel } from "components"
import toast from "react-hot-toast"

export const ProductEditFormComponent = (
  { onSuccessSaved }: { onSuccessSaved?: () => void }
) => {
  const navigate = useNavigate()

  const dispatch = useAppDispatch()

  const [products, setProducts] = useState<ProductRequest[]>([])

  const {
    watch,
    register,
    handleSubmit,
    setValue,
    formState: {
      errors
    }
  } = useForm<{
    productName: string | null
  }>()

  const productName = watch("productName")

  const productOptions = products.map(
    ({ productName }) => productName
  )

  useEffect(
    () => {
      Promise.all([
          dispatch(
            getInvoiceProducts()
          )
          
          .unwrap(),

          dispatch(
            getProduct()
          )
          
          .unwrap()
        ]
      )

      .then(
        ([
          products,
          product
        ]) => {
          if (
            product.product
          ) {
            setValue(
              "productName", product.product.productName
            )
          }

          setProducts(
            products.products
          )
        }
      )
    },
      []
  )

  /**
   * Zapisuje produkt
   * @param data Formularz
   */
  const onProductSave = (
    data: { productName: string | null }
  ) => {
    let product = products.find(
      (y) => y.productName === data.productName
    )

    if (! product) {
      product = { productName: data.productName ! }
    }

    dispatch(
      modifyProduct({
          product
        }
      )
    )

    .unwrap()

    .then(
      () => {
        if (onSuccessSaved) {
          onSuccessSaved();
        }

        toast.success("Produkt zapisany.")
      }
    )

    .catch(
      (x) => toast.error(x)
    )
  }

  /**
   * Otwiera Paywalls
   */
  const onPaywallsOpen = () => {
    navigate("/paywalls")
  }

  return (
    <>
      <form
        className="flex
          flex-col
          gap-y-2"
        onSubmit={
          handleSubmit(
            (x) => onProductSave(x)
          )
        }
      >
        {products.length ? (
          <SelectWithLabel id="productName"
            label="Słownik produktów:"
            labelFor="productName"
            selected={productName}
            options={productOptions}
            errorMessage={errors.productName?.message}
            {...register("productName", {
                required: "Wybierz produkt."
              }
            )}
            onChange={
              (value) => setValue("productName", String(value))
            }
          />
        ) : (
          <InputWithLabel id="productName"
            label="Nazwa produktu:"
            labelFor="productName"
            isError={!!errors.productName?.message}
            errorMessage={errors.productName?.message}
            {...register("productName", {
                required: "Uzupełnij nazwę produktu.", maxLength: { value: 4000, message: "Uzupełnij nazwę maksymalnie 4000 znakami." }
              }
            )}
          />
        )}

        <div className="mt-4 flex items-center justify-end gap-x-6">
          <Button
            variant="empty"
            content="Anuluj"
            onClick={
              () => onPaywallsOpen()
            }
          />

          <Button variant="filled" content="Zapisz" />
        </div>
      </form>
    </>
  )
}
