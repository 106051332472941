import { forwardRef, TextareaHTMLAttributes } from "react"
import clsx from "clsx"

type TextareaWithLabelProps = TextareaHTMLAttributes<HTMLTextAreaElement> & {
  label: string
  labelFor: string
  isError?: boolean
  errorMessage?: string
}

export const TextareaWithLabel = forwardRef<HTMLTextAreaElement, TextareaWithLabelProps>(({ label, labelFor, isError, errorMessage, ...restProps }, ref) => {
  const textareaStateClasses = clsx(isError ? "text-red-900 ring-red-300 placeholder:text-red-300 focus:ring-red-500" : "text-gray-900 ring-gray-300 placeholder:text-gray-400 focus:ring-indigo-600")
  const renderErrorMessage = () => {
    if (!errorMessage) return null

    return (
      <p className="mt-2 text-sm text-red-600" id="email-error">
        {errorMessage}
      </p>
    )
  }

  return (
    <div>
      <label htmlFor={labelFor} className="block text-sm font-medium leading-6 text-gray-900">
        Opis
      </label>
      <div className="mt-2">
        <textarea ref={ref} className={clsx("block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6", textareaStateClasses)} {...restProps} />
        {renderErrorMessage()}
      </div>
    </div>
  )
})
