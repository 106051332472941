import { useEffect } from "react"
import { useNavigate } from "react-router-dom"
import { addPaywall, getPaywalls } from "services/api.paywall"
import { raiseUser } from "services/api.user"
import { useAppDispatch, useAppSelector } from "store"
import type { PaywallBaseResponse } from "types"
import { PaywallsGrid, PaywallsGridItem } from "components"
import { PlusIcon } from "@heroicons/react/20/solid"
import { Sidebar } from "modules/_Layout/components/Sidebar"

export const PaywallsPage = () => {
  const navigate = useNavigate()

  const dispatch = useAppDispatch()

  const userToken = useAppSelector((state) => state.userAuthentication.token)

  const paywalls = useAppSelector((state) => state.paywalls.paywalls)

  const isLoadingPaywalls = useAppSelector((state) => state.paywalls.isLoading)

  useEffect(
    /**
     * Podnosi użytkownika
     */
    () => {
      if (!userToken) {
        dispatch(raiseUser())
      }
    },
    []
  )

  useEffect(
    /**
     * Pobiera kolekcję Paywalls dla zalogowanego użytkownika
     */
    () => {
      if (userToken) {
        dispatch(getPaywalls())
      }
    },
    [userToken]
  )

  /**
   * Otwiera paywall
   * @param paywall Paywall
   */
  const onPaywallOpen = (paywall: PaywallBaseResponse) => {
    navigate(`/paywalls/${paywall.paywallId}`)
  }

  /**
   * Dodaje paywall
   */
  const onPaywallAdd = () => {
    dispatch(addPaywall({ paywall: { paywallName: "Nowa bramka płatności" } }))
      .unwrap()

      .then((x) => {
        dispatch(getPaywalls())
          .unwrap()

          .then(() => {
            navigate(`/paywalls/${x.paywall.paywallId}`)
          })
      })
  }

  if (isLoadingPaywalls) {
    return <>Loader...</>
  }

  if (!userToken) {
    return <></>
  }

  const renderGridItems = () => {
    return paywalls.map(({ paywall, _links }) => {
      const { paywallLogoFileId, paywallId, paywallName, product, cardPaymentMethod, onlinePaymentMethod } = paywall || {}
      const { paywallLogoFileContentGet } = _links

      const getPaymentMethodName = () => {
        if (!!onlinePaymentMethod) {
          return onlinePaymentMethod.paymentMethodName
        }

        if (!!cardPaymentMethod) {
          return cardPaymentMethod?.paymentMethodName
        }

        return ""
      }

      return (
        <PaywallsGridItem
          key={paywallId}
          openMethod={() => {
            onPaywallOpen(paywall)
          }}
          imageUrl={paywallLogoFileId ? paywallLogoFileContentGet : ""}
          paywallName={paywallName}
          productName={product ? product.productName : ""}
          paymentMethodName={getPaymentMethodName()}
        />
      )
    })
  }

  return (
    <div className="flex h-dvh w-full">
      <Sidebar />
      <PaywallsGrid>
        {renderGridItems()}
        <li
          onClick={() => {
            onPaywallAdd()
          }}
          className="grid h-[204px] w-[300px] cursor-pointer place-items-center rounded-xl border border-gray-200 bg-white p-6"
        >
          <div className="flex flex-col items-center">
            <a className="rounded-full bg-gray-50 p-4">
              <PlusIcon className="h-5 w-5" />
            </a>
            Dodaj paywall
          </div>
        </li>
      </PaywallsGrid>
    </div>
  )
}
