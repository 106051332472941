import { createSlice } from "@reduxjs/toolkit"
import { getPaywall } from "services/api.paywall"
import { getProduct } from "services/api.product"

interface ProductState {
  isLoading: boolean
  _links: any
}

const initialState: ProductState = {
  isLoading: false,
  _links: {}
}

const productSlice = createSlice({
  name: "product",

  initialState: initialState,

  reducers: {
    //
  },

  extraReducers: (builder) =>
    builder
      //
      // Paywall
      //
      .addCase(getPaywall.fulfilled, (state, action) => {
        if (!action.payload) {
          return
        }

        const { invoiceProductsGet, productModify, productGet } = action.payload._links

        state._links = { ...state._links, invoiceProductsGet, productModify, productGet }
      })

      //
      // Produkt
      //
      .addCase(getProduct.pending, (state) => {
        state.isLoading = true
      })

      .addCase(getProduct.fulfilled, (state) => {
        state.isLoading = false
      })

      .addCase(getProduct.rejected, (state) => {
        state.isLoading = false
      })
})

export const { reducer: productReducer } = productSlice
