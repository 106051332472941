import { createSlice } from "@reduxjs/toolkit"
import { OrderBaseResponse } from "types"
import { getPaywall } from "services/api.paywall"
import { getOrders } from "services/api.order"

interface OrdersState {
  isLoading: boolean
  orders: {
    order: OrderBaseResponse
    _links: any
  }[]
  _links: any
}

const initialState: OrdersState = {
  isLoading: false,
  orders: [],
  _links: {}
}

const ordersSlice = createSlice({
  name: "orders",

  initialState: initialState,

  reducers: {
    //
  },

  extraReducers: (builder) =>
    builder
      //
      // Paywall
      //
      .addCase(getPaywall.fulfilled, (state, action) => {
        if (!action.payload) {
          return
        }

        const { ordersGet } = action.payload._links

        state._links = { ...state._links, ordersGet }
      })

      //
      // Orders
      //
      .addCase(getOrders.pending, (state) => {
        state.isLoading = true
      })

      .addCase(getOrders.fulfilled, (state, action) => {
        state.orders = action.payload.orders

        state.isLoading = false
      })

      .addCase(getOrders.rejected, (state) => {
        state.isLoading = false
      })
})

export const { reducer: ordersReducer } = ordersSlice
