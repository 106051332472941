import { createSlice } from "@reduxjs/toolkit"
import { getInvoicePaymentMethods, getPaymentMethod } from "services/api.paymentMethod"
import { getPaywall } from "services/api.paywall"
import { PaymentMethodRequest } from "types"

interface PaymentMethodState {
  isLoading: boolean
  paymentMethods: PaymentMethodRequest[]
  _links: any
}

const initialState: PaymentMethodState = {
  isLoading: false,
  paymentMethods: [],
  _links: {}
}

const paymentMethodSlice = createSlice({
  name: "paymentMethod",

  initialState: initialState,

  reducers: {
    //
  },

  extraReducers: (builder) =>
    builder
      //
      // Paywall
      //
      .addCase(getPaywall.fulfilled, (state, action) => {
        if (!action.payload) {
          return
        }

        const { invoicePaymentMethodsGet, paymentMethodModify, paymentMethodGet } = action.payload._links

        state._links = { ...state._links, invoicePaymentMethodsGet, paymentMethodModify, paymentMethodGet }
      })

      //
      // Invoice Payment Methods
      //
      .addCase(getInvoicePaymentMethods.fulfilled, (state, action) => {
        state.paymentMethods = action.payload.paymentMethods
      })

      //
      // Payment Method
      //
      .addCase(getPaymentMethod.pending, (state) => {
        state.isLoading = true
      })

      .addCase(getPaymentMethod.fulfilled, (state) => {
        state.isLoading = false
      })

      .addCase(getPaymentMethod.rejected, (state) => {
        state.isLoading = false
      })
})

export const { reducer: paymentMethodReducer } = paymentMethodSlice
