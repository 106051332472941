import { HomeIcon, UserCircleIcon } from "@heroicons/react/24/outline"
import clsx from "clsx"
import { Link } from "react-router-dom"
import Logo from "assets/logo.png"

const navigation = [
  {
    name: "Lista paywall",
    href: "/paywalls",
    icon: HomeIcon,
    current: true
  }
]

export function Sidebar() {
  return (
    <>
      <input type="checkbox" id="menu-toggle" name="menu-toggle" className="peer sr-only" />
      <label htmlFor="menu-toggle" aria-label="Toggle menu open" className="fixed left-0 top-0 z-[999] h-auto p-4 xl:sr-only">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" className="h-8 w-8 text-indigo-700">
          <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
        </svg>
      </label>

      <div className="fixed inset-0 z-[888] flex w-full max-w-[300px] grow flex-col gap-y-5 overflow-y-auto border-r border-gray-200 bg-white px-6 pt-16 peer-checked:hidden xl:static xl:flex xl:pt-0">
        <div className="sr-only xl:not-sr-only xl:flex xl:h-16 xl:shrink-0 xl:items-center">
          <img className="h-8 w-auto" src={Logo} alt="Paywallingi" />
        </div>

        <nav className="flex flex-1 flex-col pt-16 xl:pt-0">
          <ul role="list" className="flex flex-1 flex-col gap-y-7">
            <li>
              <ul role="list" className="-mx-2 space-y-1">
                {navigation.map((item) => (
                  <li key={item.name}>
                    <Link to={item.href}
                      className={
                        clsx(
                          "group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold",
                          item.current ? "bg-gray-50 text-indigo-600" : "text-gray-700 hover:bg-gray-50 hover:text-indigo-600"
                        )
                      }
                    >
                      <item.icon className={clsx(item.current ? "text-indigo-600" : "text-gray-400 group-hover:text-indigo-600", "h-6 w-6 shrink-0")} aria-hidden="true" />
                      {item.name}
                    </Link>
                  </li>
                ))}
              </ul>
            </li>
            <li className="-mx-6 mt-auto">
              <Link to="/user" className="flex items-center gap-x-4 px-6 py-3 text-sm font-semibold leading-6 text-gray-900 hover:bg-gray-50">
                <UserCircleIcon className="h-8 w-8 rounded-full bg-gray-50" />
                {/* <span className="sr-only">Your profile</span> */}
                <span aria-hidden="true">Użytkownik</span>
              </Link>
            </li>
          </ul>
        </nav>
      </div>
    </>
  )
}
