import { createSlice } from "@reduxjs/toolkit"

interface IndexState {
  _links: any
}

const initialState: IndexState = {
  _links: {
    "indexGet": ""
  }
}

const indexSlice = createSlice({
  name: "index",

  initialState: initialState,

  reducers: {
    //
  }
})

export const { reducer: indexReducer } = indexSlice
