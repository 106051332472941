import { MessageToast } from "components/toasts/MessageToast"
import { PaywallPage } from "modules/Paywall/pages/Paywall.page"
import { PaywallsPage } from "modules/Paywall/pages/Paywalls.page"
import { UserPage } from "modules/User/pages/User.page"
import { UserPassword2Page } from "modules/User/pages/UserPassword2.page"
import { UserSigninPage } from "modules/User/pages/UserSignin.page"
import { useEffect, useState } from "react"
import { Toaster } from "react-hot-toast"
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom"
import { getIndex } from "services/api.index"
import { getServerIpAddress } from "services/api.server"
import { useAppDispatch, useAppSelector } from "store"

const Page = () => {
  const dispatch = useAppDispatch()

  const [initialize, setInitialize] = useState<boolean>(false)

  const userToken = useAppSelector((state) => state.userAuthentication.token)

  useEffect(
    () => {
      dispatch(
        getIndex()
      )

      .unwrap()

      .then(
        () => {
          dispatch(
            getServerIpAddress()
          )

          .unwrap()

          setInitialize(true)
        }
      )
    },
      []
  )

  if (
    ! initialize
  ) {
    return <>Loader...</>
  }

  return (
    <>
      <Toaster position="top-right">
        {(t) => <MessageToast t={t} />}
      </Toaster>

      <Routes>
        {userToken ? (
          <>
            <Route path={"/paywalls/:paywallId"} element={<PaywallPage />} />
          </>
        ) : null}

        <Route path={"/paywalls"} element={<PaywallsPage />} />

        <Route path={"/user/signin"} element={<UserSigninPage />} />

        <Route path={"/user/password2"} element={<UserPassword2Page />} />

        {userToken ? (
          <>
            <Route path={"/user"} element={<UserPage />} />
          </>
        ) : null}

        <Route path={"*"} element={<Navigate to={"/paywalls"} />} />
      </Routes>
    </>
  )
}

export const App = () => {
  return (
    <>
      <BrowserRouter basename="/Admin">
        <Page />
      </BrowserRouter>
    </>
  )
}
