import clsx from "clsx"
import { ExclamationCircleIcon } from "@heroicons/react/16/solid"
import { forwardRef, InputHTMLAttributes } from "react"

type InputWithLabelProps = InputHTMLAttributes<HTMLInputElement> & {
  label: string
  labelFor: string
  isError?: boolean
  errorMessage?: string
}

export const InputWithLabel = forwardRef<HTMLInputElement, InputWithLabelProps>(({ label, labelFor, isError, errorMessage, ...restProps }, ref) => {
  const inputStateClasses = clsx(isError ? "text-red-900 ring-red-300 placeholder:text-red-300 focus:ring-red-500" : "text-gray-900 ring-gray-300 placeholder:text-gray-400 focus:ring-indigo-600")

  const renderErrorIcon = () => {
    if (!isError) return null

    return (
      <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
        <ExclamationCircleIcon className="h-5 w-5 text-red-500" aria-hidden="true" />
      </div>
    )
  }

  const renderErrorMessage = () => {
    if (!errorMessage) return null

    return (
      <p className="mt-2 text-sm text-red-600" id="email-error">
        {errorMessage}
      </p>
    )
  }

  return (
    <div>
      <label htmlFor={labelFor} className="block text-sm font-medium leading-6 text-gray-900">
        {label}
      </label>
      <div className="relative mt-2 rounded-md shadow-sm">
        <input ref={ref} className={clsx("sm:text-sm sm:leading-6 block w-full rounded-md border-0 py-1.5 ring-1 ring-inset focus:ring-2 focus:ring-inset", inputStateClasses)} {...restProps} />
        {renderErrorIcon()}
      </div>
      {renderErrorMessage()}
    </div>
  )
})
