import { createSlice } from "@reduxjs/toolkit"
import { getConfigurationOnlinePayment } from "services/api.configurationOnlinePayment"
import { getPaywall } from "services/api.paywall"

interface ConfigurationOnlinePaymentState {
  isLoading: boolean
  _links: any
}

const initialState: ConfigurationOnlinePaymentState = {
  isLoading: false,
  _links: {}
}

const configurationOnlinePaymentSlice = createSlice({
  name: "configurationOnlinePayment",

  initialState: initialState,

  reducers: {
    //
  },

  extraReducers: (builder) =>
    builder
      //
      // Paywall
      //
      .addCase(getPaywall.fulfilled, (state, action) => {
        if (!action.payload) {
          return
        }

        const { configurationOnlinePaymentModify, configurationOnlinePaymentGet } = action.payload._links

        state._links = { ...state._links, configurationOnlinePaymentModify, configurationOnlinePaymentGet }
      })

      //
      // Configuration Online Payment
      //
      .addCase(getConfigurationOnlinePayment.pending, (state) => {
        state.isLoading = true
      })

      .addCase(getConfigurationOnlinePayment.fulfilled, (state) => {
        state.isLoading = false
      })

      .addCase(getConfigurationOnlinePayment.rejected, (state) => {
        state.isLoading = false
      })
})

export const { reducer: configurationOnlinePaymentReducer } = configurationOnlinePaymentSlice
