import { Fragment, ReactNode } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { ExclamationTriangleIcon, XMarkIcon } from '@heroicons/react/24/outline'

export const DialogComponent = (
  {
    title,
    content,
    actionsBar,
    visible,
    onVisibleChange,
  }: {
    title: () => ReactNode | string
    content: () => ReactNode | string
    actionsBar: () => ReactNode | string
    visible: boolean
    onVisibleChange: (x: boolean) => void
  }
) => {
  return (
    <Transition.Root show={visible} as={Fragment}>
      <Dialog className="relative z-[99999]" as="div"
        onClose={
          (x) => {
            onVisibleChange(x)
          }
        }
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div
            className="fixed
              inset-0
              bg-gray-500
              bg-opacity-75
              transition-opacity"
            />
        </Transition.Child>

        <div
          className="fixed
            inset-0
            z-10
            w-screen
            overflow-y-auto"
        >
          <div
            className="flex
              min-h-full
              items-end
              justify-center
              p-4
              text-center
              sm:items-center
              sm:p-0"
          >
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel
                className="relative
                  transform
                  overflow-hidden
                  rounded-lg
                  bg-white
                  px-4
                  pb-4
                  pt-5
                  text-left
                  shadow-xl
                  transition-all
                  sm:my-8
                  sm:w-full
                  sm:max-w-lg
                  sm:p-6"
              >
                <div
                  className="absolute
                    right-0
                    top-0
                    hidden
                    pr-4
                    pt-4
                    sm:block"
                >
                  <button
                    className="rounded-md
                      bg-white
                      text-gray-400
                      hover:text-gray-500
                      focus:outline-none
                      focus:ring-2
                      focus:ring-indigo-500
                      focus:ring-offset-2"
                    type="button"
                    onClick={
                      () => {
                        onVisibleChange(false)
                      }
                    }
                  >
                    <span className="sr-only">Close</span>

                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>

                <div className="sm:flex sm:items-start">
                  <div
                    className="mx-auto
                      flex
                      h-12
                      w-12
                      flex-shrink-0
                      items-center
                      justify-center
                      rounded-full
                      bg-red-100
                      sm:mx-0
                      sm:h-10
                      sm:w-10"
                  >
                    <ExclamationTriangleIcon className="h-6 w-6 text-red-600" aria-hidden="true" />
                  </div>

                  <div
                    className="mt-3
                      text-center
                      sm:ml-4
                      sm:mt-0
                      sm:text-left"
                  >
                    <Dialog.Title as="h3"
                      className="text-base
                        font-semibold
                        leading-6
                        text-gray-900"
                    >
                      {title()}
                    </Dialog.Title>

                    <div className="mt-2">
                      <p className="text-sm text-gray-500">
                        {content()}
                      </p>
                    </div>
                  </div>
                </div>

                <div
                  className="mt-5
                    sm:mt-4
                    sm:flex
                    sm:flex-row-reverse
                    sm:gap-4"
                >
                  {actionsBar()}
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};
