import { useEffect } from "react"
import { useForm } from "react-hook-form"
import { useNavigate } from "react-router-dom"
import { addFile } from "services/api.file"
import { getStatement, modifyStatement } from "services/api.statement"
import { useAppDispatch, useAppSelector } from "store"
import { Button } from "components"
import toast from "react-hot-toast"

export const StatementEditFormComponent = (
  { onSuccessSaved }: { onSuccessSaved?: () => void }
) => {
  const navigate = useNavigate()

  const dispatch = useAppDispatch()

  const { setValue, watch, register, handleSubmit } = useForm<{
    orderRulesFileId: string | null
    privacyPolicyFileId: string | null
    orderRulesFiles: any[]
    privacyPolicyFiles: any[]
  }>()

  const orderRulesFileId = watch("orderRulesFileId")

  const privacyPolicyFileId = watch("privacyPolicyFileId")

  const orderRulesFiles = watch("orderRulesFiles")

  const privacyPolicyFiles = watch("privacyPolicyFiles")

  const statement = useAppSelector((x) => x.statement.statement)

  useEffect(() => {
    dispatch(getStatement())
      .unwrap()

      .then((x) => {
        setValue("orderRulesFileId", x.statement?.statementOrderRulesDocumentFileId)

        setValue("privacyPolicyFileId", x.statement?.statementPrivacyPolicyDocumentFileId)
      })

      .catch(() => {
        // UNDONE
      })
  }, [])

  /**
   * Zapisuje oświadczenia
   * @param data Formularz
   */
  const onStatementSave = (
    data: { orderRulesFileId: string | null; privacyPolicyFileId: string | null; orderRulesFiles: any[]; privacyPolicyFiles: any[] }
  ) => {
    const orderRulesFormData = new FormData()

    if (
         data.orderRulesFiles
      && data.orderRulesFiles.length
    ) {
      orderRulesFormData.append("file", data.orderRulesFiles[0])
    }

    const privacyPolicyFormData = new FormData()

    if (
         data.privacyPolicyFiles
      && data.privacyPolicyFiles.length
    ) {
      privacyPolicyFormData.append("file", data.privacyPolicyFiles[0])
    }

    return Promise.all([
        dispatch(
          addFile(orderRulesFormData)
        )
        
        .unwrap(),
        
        dispatch(
          addFile(privacyPolicyFormData)
        )
        
        .unwrap()
      ]
    )

    .then(
      ([
        orderRulesFile,
        privacyPolicyFile
      ]) => {
        return dispatch(
          modifyStatement({
              statement: { statementOrderRulesDocumentFileId: orderRulesFile.file?.fileId ?? data.orderRulesFileId, statementPrivacyPolicyDocumentFileId: privacyPolicyFile.file?.fileId ?? data.privacyPolicyFileId }
            }
          )
        )
        
        .unwrap()
      }
    )

    .then(
      () => {
        if (onSuccessSaved) {
          onSuccessSaved();
        }

        toast.success("Dokumenty zapisane.")
      }
    )

    .catch(
      (x) => toast.error(x)
    )
  }

  /**
   * Otwiera Paywalls
   */
  const onPaywallsOpen = () => {
    navigate("/paywalls")
  }

  return (
    <>
      <form
        onSubmit={handleSubmit((x) => {
          onStatementSave(x)
          console.log(x)
        })}
        className="flex flex-col gap-y-2"
      >
        <div>
          <label className="flex flex-col gap-y-2 text-sm font-medium leading-6 text-gray-900">
            Regulamin:
            {!orderRulesFiles?.length && orderRulesFileId ? <input type={"hidden"} {...register("orderRulesFileId")} /> : null}
            <div className="flex flex-row items-center gap-x-2">
              <input type="file" id="orderRulesFiles" {...register("orderRulesFiles")} hidden />
              <label
                htmlFor="orderRulesFiles"
                className="block min-w-fit cursor-pointer rounded-md border-0
                  bg-indigo-500 px-4 py-2 text-sm
                  font-semibold text-white hover:bg-indigo-600"
              >
                Choose file
              </label>
              <label className="truncate text-sm text-indigo-500">{orderRulesFiles?.length ? orderRulesFiles[0]?.name : statement?.statementOrderRulesDocumentFileName}</label>
            </div>
          </label>
        </div>
        <div>
          <label className="flex flex-col gap-y-2 text-sm font-medium leading-6 text-gray-900">
            Polityka prywatności:
            {!privacyPolicyFiles?.length && privacyPolicyFileId ? <input type={"hidden"} {...register("privacyPolicyFileId")} /> : null}
            <div className="flex flex-row items-center gap-x-2">
              <input type="file" id="privacyPolicyFiles" {...register("privacyPolicyFiles")} hidden />
              <label
                htmlFor="privacyPolicyFiles"
                className="block min-w-fit cursor-pointer rounded-md border-0
                  bg-indigo-500 px-4 py-2 text-sm
                  font-semibold text-white hover:bg-indigo-600"
              >
                Choose file
              </label>
              <label className="truncate text-sm text-indigo-500">{privacyPolicyFiles?.length ? privacyPolicyFiles[0]?.name : statement?.statementPrivacyPolicyDocumentFileName}</label>
            </div>
          </label>
        </div>

        <div className="mt-4 flex items-center justify-end gap-x-6">
          <Button
            variant="empty"
            content="Anuluj"
            onClick={() => {
              onPaywallsOpen()
            }}
          />
          <Button variant="filled" content="Zapisz" />
        </div>
      </form>
    </>
  )
}
