import { createSlice } from "@reduxjs/toolkit"
import { getIndex } from "services/api.index"

interface UserState {
  _links: any
}

const initialState: UserState = {
  _links: {}
}

const userSlice = createSlice({
  name: "user",

  initialState: initialState,

  reducers: {
    //
  },

  extraReducers: (builder) =>
    builder
      //
      // Index
      //
      .addCase(getIndex.fulfilled,
        (state, action) => {
          const { userRaise, userAdd, userPasswordModify, userPassword2Raise, userSignin } = action.payload._links

          state._links = { ...state._links, userRaise, userAdd, userPasswordModify, userPassword2Raise, userSignin }
        }
      )
})

export const { reducer: userReducer } = userSlice
