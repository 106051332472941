import { Button, InputWithLabel } from "components"
import { useForm } from "react-hook-form"
import toast from "react-hot-toast"
import { modifyUserPassword } from "services/api.user"
import { useAppDispatch } from "store"

export const UserPasswordEditFormComponent = () => {
  const dispatch = useAppDispatch()

  const {
    register,
    handleSubmit,
    formState: {
      errors
    }
  } = useForm<{
      userOldPassword: string
      userNewPassword: string
    }
  >()

  /**
   * Modyfikuje hasło
   * @param data Formularz
   */
  const onUserPasswordModify = (
    data: {
      userOldPassword: string
      userNewPassword: string
    }
  ) => {
    dispatch(
      modifyUserPassword({
          user: data
        }
      )
    )

    .unwrap()

    .then(
      () => toast.success("Hasło zmodyfikowane.")
    )

    .catch(
      (x) => toast.error(x)
    )
  }

  return (
    <form
      className="flex flex-col gap-y-6"
      onSubmit={
        handleSubmit(
          (x) => onUserPasswordModify(x)
        )
      }
    >
      <div
        className="flex
          max-w-2xl
          flex-1
          flex-col
          gap-y-6
          sm:w-full"
      >
        <InputWithLabel
          type={"password"}
          label="Aktualne hasło:"
          labelFor={"userOldPassword"}
          isError={!!errors.userOldPassword}
          errorMessage={errors.userOldPassword?.message}
          {...register("userOldPassword",
              { required: "Uzupełnij aktualne hasło.", maxLength: { value: 4000, message: "Uzupełnij maksylanie 4000 znakami." } }
            )
          }
        />

        <InputWithLabel
          type={"password"}
          label="Nowe hasło:"
          labelFor={"userNewPassword"}
          isError={!!errors.userNewPassword}
          errorMessage={errors.userNewPassword?.message}
          {...register("userNewPassword",
              { required: "Uzupełnij nowe hasło.", maxLength: { value: 4000, message: "Uzupełnij maksylanie 4000 znakami." } }
            )
          }
        />
      </div>

      <div
        className="flex
          w-full
          justify-end
          border-t
          border-gray-900/10
          pt-4"
      >
        <Button variant={"filled"} type={"submit"} content="Zmień hasło" />
      </div>
    </form>
  )
}
