import { forwardRef, Fragment, SelectHTMLAttributes } from "react"
import { Listbox, Transition } from "@headlessui/react"
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/20/solid"
import clsx from "clsx"

type SelectWithLabelProps = SelectHTMLAttributes<HTMLSelectElement> & {
  label: string
  labelFor: string
  selected: string | null
  onChange: (value: string) => void
  options: string[]
  errorMessage?: string
}

export const SelectWithLabel = forwardRef<HTMLSelectElement, SelectWithLabelProps>(
  ({ label, labelFor, selected, onChange, options, errorMessage, ...restProps }, ref) => {
    const { ...otherProps } = restProps

    const renderErrorMessage = () => {
      if (!errorMessage) {
        return null
      }
  
      return (
        <p
          className="mt-2
            text-sm
            text-red-600"
          >
          {errorMessage}
        </p>
      )
    }
  
    return (
      <div>
        <Listbox ref={ref} value={selected} onChange={onChange} {...otherProps}>
          {({ open }) => (
            <>
              <Listbox.Label htmlFor={labelFor}
                className="block
                  text-sm
                  font-medium
                  leading-6
                  text-gray-900"
              >
                {label}
              </Listbox.Label>

              <div className="relative mt-2">
                <Listbox.Button
                  className="sm:text-sm
                    sm:leading-6
                    relative
                    min-h-9
                    w-full
                    cursor-default
                    rounded-md
                    bg-white
                    px-3
                    py-1.5
                    text-left
                    text-gray-900
                    shadow-sm
                    ring-1
                    ring-inset
                    ring-gray-300
                    focus:outline-none
                    focus:ring-2
                    focus:ring-indigo-600"
                >
                  <span className="block truncate">
                    {selected}
                  </span>

                  <span
                    className="pointer-events-none
                      absolute
                      inset-y-0
                      right-0
                      flex
                      items-center
                      pr-2"
                  >
                    <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                  </span>
                </Listbox.Button>

                <Transition show={open} as={Fragment}
                  leave="transition ease-in duration-100"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <Listbox.Options
                    className="sm:text-sm
                      absolute
                      z-10
                      mt-1
                      max-h-60
                      w-full
                      overflow-auto
                      rounded-md
                      bg-white
                      py-1
                      text-base
                      shadow-lg
                      ring-1
                      ring-black
                      ring-opacity-5
                      focus:outline-none"
                  >
                    {[null, ...options].map((option) => {
                      return (
                        <Listbox.Option key={option} value={option}
                          className={({ active }) => clsx("relative cursor-default select-none py-2 pl-3 pr-9", active ? "bg-indigo-600 text-white" : "text-gray-900")}
                        >
                          {({ selected, active }) => (
                            <>
                              <span className={clsx("block truncate min-h-4", selected ? "font-semibold" : "font-normal")}>
                                {option}
                              </span>

                              {selected ? (
                                <span className={clsx("absolute inset-y-0 right-0 flex items-center pr-4", active ? "text-white" : "text-indigo-600")}>
                                  <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                </span>
                              ) : null}
                            </>
                          )}
                        </Listbox.Option>
                      )
                    })}
                  </Listbox.Options>
                </Transition>
              </div>
            </>
          )}
        </Listbox>

        {renderErrorMessage()}
      </div>
    )
  }
)
