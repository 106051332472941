import { Fragment, useEffect } from "react"
import { getOrders } from "services/api.order"
import { useAppDispatch, useAppSelector } from "store"

const formatter = new Intl.NumberFormat("pl-PL", {
  style: "currency",
  currency: "PLN",
  useGrouping: true
})

export const OrdersComponent = () => {
  const dispatch = useAppDispatch()

  const orders = useAppSelector((state) => state.orders.orders)

  useEffect(() => {
    dispatch(getOrders()).unwrap()
  }, [])

  return (
    <div className="absolute left-0 top-0 flex h-dvh w-full">
      <div className="relative z-10 h-full w-full max-w-7xl p-5">
        <div className="overflow-hidden border-t border-gray-100">
          <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
            <div className="mx-auto max-w-2xl lg:mx-0 lg:max-w-none">
              <table className="w-full text-left">
                <thead className="sr-only">
                  <tr>
                    <th>Status płatności</th>
                    <th className="hidden sm:table-cell">Klient</th>
                    <th>Więcej szczegółów</th>
                  </tr>
                </thead>
                <tbody>
                  <Fragment>
                    {/* <tr className="text-sm leading-6 text-gray-900">
                      <th scope="colgroup" colSpan={3} className="relative isolate py-2 font-semibold">
                        <time dateTime={""}>Poniedziałek</time>
                        <div className="absolute inset-y-0 right-full -z-10 w-screen border-b border-gray-200 bg-gray-50" />
                        <div className="absolute inset-y-0 left-0 -z-10 w-screen border-b border-gray-200 bg-gray-50" />
                      </th>
                    </tr> */}
                    {orders.map(({ order }) => (
                      <tr key={order.orderId}>
                        <td className="relative py-5 pr-6">
                          <div className="flex gap-x-6">
                            {/* <ArrowUpCircleIcon className="hidden h-6 w-5 flex-none text-gray-400 sm:block" aria-hidden="true" /> */}
                            <div className="flex-auto">
                              <div className="flex items-start gap-x-3">
                                <div className="text-sm font-medium leading-6 text-gray-900">{formatter.format(order.orderPaymentPrice)}</div>
                                <div className={"rounded-md px-2 py-1 text-xs font-medium ring-1 ring-inset"}>{order.orderPaidStatusName}</div>
                              </div>
                              <div className="mt-1 text-xs leading-5 text-gray-500">{new Date(order.orderPaidStatusDate).toLocaleString()}</div>
                            </div>
                          </div>
                          <div className="absolute bottom-0 right-full h-px w-screen bg-gray-100" />
                          <div className="absolute bottom-0 left-0 h-px w-screen bg-gray-100" />
                        </td>
                        <td className="hidden py-5 pr-6 sm:table-cell">
                          <div className="text-sm leading-6 text-gray-900">{order.orderCustomerName}</div>
                          <div className="mt-1 text-xs leading-5 text-gray-500">{order.orderCustomerTaxNo}</div>
                        </td>
                        <td className="py-5 text-right">
                          <div className="mt-1 text-xs leading-5 text-gray-500">
                            Dokument: <span className="text-gray-900">{order.orderNumber}</span>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </Fragment>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
