import { createAsyncThunk } from "@reduxjs/toolkit"
import { ConfigurationOnlinePaymentPaynowRequest, ConfigurationOnlinePaymentPayuRequest, ConfigurationOnlinePaymentPlanetPayRequest, ConfigurationOnlinePaymentPrzelewy24Request, ConfigurationOnlinePaymentTpayRequest } from "types"
import { api } from "./api"
import { resolveMessage } from "./api.error"

export interface ConfigurationOnlinePaymentGetResponse {
  configurationOnlinePaymentPaynow?: ConfigurationOnlinePaymentPaynowRequest
  configurationOnlinePaymentPayu?: ConfigurationOnlinePaymentPayuRequest
  configurationOnlinePaymentPlanetPay?: ConfigurationOnlinePaymentPlanetPayRequest
  configurationOnlinePaymentPrzelewy24?: ConfigurationOnlinePaymentPrzelewy24Request
  configurationOnlinePaymentTpay?: ConfigurationOnlinePaymentTpayRequest
}

export const modifyConfigurationOnlinePayment = createAsyncThunk("app/modifyConfigurationOnlinePayment", (
    request: { configurationOnlinePaymentPaynow?: ConfigurationOnlinePaymentPaynowRequest; configurationOnlinePaymentPayu?: ConfigurationOnlinePaymentPayuRequest; configurationOnlinePaymentPlanetPay?: ConfigurationOnlinePaymentPlanetPayRequest; configurationOnlinePaymentPrzelewy24?: ConfigurationOnlinePaymentPrzelewy24Request; configurationOnlinePaymentTpay?: ConfigurationOnlinePaymentTpayRequest }, { getState, rejectWithValue }
  ) => {
    const state: any = getState()

    const { configurationOnlinePaymentModify } = state.configurationOnlinePayment._links

    return api.put(configurationOnlinePaymentModify, request)

    .catch(
      (x) => {
        let errors = ""

        if (
          "errors" in x.response.data
        ) {
          errors = resolveMessage(
            x.response.data.errors
          )
        }

        return rejectWithValue(errors)
      }
    )
  }
)

export const getConfigurationOnlinePayment = createAsyncThunk("app/getConfigurationOnlinePayment", (
    _1, { getState }
  ) => {
    const state: any = getState()

    const { configurationOnlinePaymentGet } = state.configurationOnlinePayment._links

    return api.get<ConfigurationOnlinePaymentGetResponse>(configurationOnlinePaymentGet)

    .then(
      (x) => x.data
    )
  }
)
