import { createSlice, PayloadAction } from "@reduxjs/toolkit"

interface UserAuthenticationState {
  token: string | null
}

const initialState: UserAuthenticationState = {
  token: window.localStorage.getItem("userAuthentication:token")
}

const userAuthenticationSlice = createSlice({
  name: "userAuthentication",

  initialState: initialState,

  reducers: {
    setToken:
      /**
       * Ustawia token
       */
      (state, action: PayloadAction<string>) => {
        state.token = action.payload

        window.localStorage.setItem("userAuthentication:token", action.payload)
      },

    removeToken:
      /**
       * Usuwa token
       */
      (state) => {
        state.token = null

        window.localStorage.removeItem("userAuthentication:token")
      }
  }
})

export const { setToken, removeToken } = userAuthenticationSlice.actions

export const { reducer: userAuthenticationReducer } = userAuthenticationSlice
