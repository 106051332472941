import { useDispatch, useSelector, TypedUseSelectorHook } from "react-redux"
import { configureStore } from "@reduxjs/toolkit"
import { configurationInvoiceReducer } from "modules/Configuration/ConfigurationInvoice.slice"
import { configurationOnlinePaymentReducer } from "modules/Configuration/ConfigurationOnlinePayment.slice"
import { fileReducer } from "modules/File/File.slice"
import { indexReducer } from "modules/Index/Index.slice"
import { ordersReducer } from "modules/Order/Orders.slice"
import { paymentMethodReducer } from "modules/PaymentMethod/PaymentMethod.slice"
import { paywallsReducer } from "modules/Paywall/Paywalls.slice"
import { paywallReducer } from "modules/Paywall/Paywall.slice"
import { productReducer } from "modules/Product/Product.slice"
import { serverReducer } from "modules/Server/Server.slice"
import { statementReducer } from "modules/Statement/Statement.slice"
import { userReducer } from "modules/User/User.slice"
import { userAuthenticationReducer } from "modules/User/UserAuthentication.slice"
import { instanceReducer } from "modules/Instance/Instance.slice"

export const store = configureStore({
  reducer: {
    configurationInvoice: configurationInvoiceReducer,
    configurationOnlinePayment: configurationOnlinePaymentReducer,
    file: fileReducer,
    index: indexReducer,
    instance: instanceReducer,
    orders: ordersReducer,
    paymentMethod: paymentMethodReducer,
    paywalls: paywallsReducer,
    paywall: paywallReducer,
    product: productReducer,
    server: serverReducer,
    statement: statementReducer,
    user: userReducer,
    userAuthentication: userAuthenticationReducer
  }
})

export type AppStore = typeof store

export type AppDispatch = typeof store.dispatch

export type AppState = ReturnType<typeof store.getState>

export const useAppDispatch = () => useDispatch<AppDispatch>()

export const useAppSelector: TypedUseSelectorHook<AppState> = useSelector
