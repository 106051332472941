import { createAsyncThunk } from "@reduxjs/toolkit"
import { ConfigurationInvoiceBillingRequest, ConfigurationInvoiceFakturowniaRequest, ConfigurationInvoiceWfirmaRequest } from "types"
import { api } from "./api"
import { resolveMessage } from "./api.error"

export interface ConfigurationInvoiceGetResponse {
  configurationInvoiceBillingi?: ConfigurationInvoiceBillingRequest
  configurationInvoiceFakturownia?: ConfigurationInvoiceFakturowniaRequest
  configurationInvoiceWfirma?: ConfigurationInvoiceWfirmaRequest
}

export const modifyConfigurationInvoice = createAsyncThunk("app/modifyConfigurationInvoice", (
    request: { configurationInvoiceBillingi?: ConfigurationInvoiceBillingRequest; configurationInvoiceFakturownia?: ConfigurationInvoiceFakturowniaRequest; configurationInvoiceWfirma?: ConfigurationInvoiceWfirmaRequest; }, { getState, rejectWithValue }
  ) => {
    const state: any = getState()

    const { configurationInvoiceModify } = state.configurationInvoice._links

    return api.put(configurationInvoiceModify, request)

    .catch(
      (x) => {
        let errors = ""

        if (
          "errors" in x.response.data
        ) {
          errors = resolveMessage(
            x.response.data.errors
          )
        }

        return rejectWithValue(errors)
      }
    )
  }
)

export const getConfigurationInvoice = createAsyncThunk("app/getConfigurationInvoice", (
    _1, { getState }
  ) => {
    const state: any = getState()

    const { configurationInvoiceGet } = state.configurationInvoice._links

    return api.get<ConfigurationInvoiceGetResponse>(configurationInvoiceGet)

    .then(
      (x) => x.data
    )
  }
)
