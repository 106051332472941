import { jwtDecode } from "jwt-decode"
import { useEffect, useRef, useState } from "react"
import { Link, useNavigate, useParams } from "react-router-dom"
import { deletePaywall, getPaywall } from "services/api.paywall"
import { useAppDispatch, useAppSelector } from "store"
import { PaywallEditFormComponent } from "../components/PaywallEditForm.component"
import { ConfigurationInvoiceEditForm } from "modules/Configuration/components/ConfigurationInvoiceEditForm.component"
import { ConfigurationOnlinePaymentEditFormComponent } from "modules/Configuration/components/ConfigurationOnlinePaymentEditForm.component"
import { PaymentMethodEditFormComponent } from "modules/PaymentMethod/components/PaymentMethodEditForm.component"
import { StatementEditFormComponent } from "modules/Statement/components/StatementEditForm.component"
import { ProductEditFormComponent } from "modules/Product/components/ProductEditForm.component"
import { setPaywall } from "../Paywall.slice"
import { AccordionWrapper, Button } from "components"
import Background from "assets/background.png"
import Logo from "assets/logo.png"
import clsx from "clsx"
import { Bars4Icon, CurrencyEuroIcon, DocumentIcon, GlobeAltIcon, HomeIcon, ShareIcon, TagIcon, TrashIcon, UserCircleIcon } from "@heroicons/react/24/outline"
import { ChevronLeftIcon, UsersIcon } from "@heroicons/react/16/solid"
import { OrdersComponent } from "../../Order/components/Orders.component"
import { DialogComponent } from "components/Dialog.component"
import { InstanceEditFormComponent } from "modules/Instance/components/InstanceEditForm.component"

export const PaywallPage = () => {
  const navigate = useNavigate();

  const [isOrdersOpen, setIsOrdersOpen] = useState(false)

  const [initializeUser, setInitializeUser] = useState(false)

  const { paywallId } = useParams()

  const dispatch = useAppDispatch()

  const userToken = useAppSelector((state) => state.userAuthentication.token)

  const paywall = useAppSelector((state) => state.paywall.paywall)

  const { statementPrivacyPolicyDocumentFileContentGet, statementOrderRulesDocumentFileContentGet, paywallLogoFileContentGet, defaultOrder } = useAppSelector((state) => state.paywall._links)

  const isLoadingPaywall = useAppSelector((state) => state.paywall.isLoading)

  const isLoadingConfigurationInvoice = useAppSelector((state) => state.configurationInvoice.isLoading)

  const isLoadingConfigurationOnlinePayment = useAppSelector((state) => state.configurationOnlinePayment.isLoading)

  const isLoadingPaymentMethod = useAppSelector((state) => state.paymentMethod.isLoading)

  const isLoadingProduct = useAppSelector((state) => state.product.isLoading)

  const isLoadingStatement = useAppSelector((state) => state.statement.isLoading)

  const isLoadingOrders = useAppSelector((state) => state.orders.isLoading)

  const isLoadingInstance = useAppSelector((state) => state.instance.isLoading)

  const decoded: any = userToken && jwtDecode(userToken)

  const isAuthentication = decoded["http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name"]

  var defaultOrderInput = useRef<HTMLInputElement>(null)

  useEffect(
    /**
     * Pobiera Paywall
     */
    () => {
      if (paywallId) {
        dispatch(
          getPaywall(paywallId)
        )
      }

      return () => {
        dispatch(
          setPaywall(null)
        )
      }
    },
      [ paywallId ]
  )

  /**
   * Kopiuje do schowka adres URL domyślnego zamówienia
   */
  const onCopyDefaultOrderUrl = () => {
    defaultOrderInput.current?.focus();

    defaultOrderInput.current?.select();

    window.document.execCommand("copy");
  };

  /**
   * 
   */
  const onDeletePaywall = () => {
    dispatch(
      deletePaywall()
    )

    .unwrap()

    .then(
      () => navigate("/paywalls")
    )

    .catch(
      () => {
        // UNDONE
      }
    )
  };

  /**
   * Weryfikuje konto użytkownika po zapisaniu Paywall
   */
  const onSuccessSaved = () => {
    if (
      ! isAuthentication
    ) {
      setInitializeUser(true)
    }
  }

  if (
    ! paywall
  ) {
    return <></>
  }

  if (isLoadingPaywall || isLoadingConfigurationInvoice || isLoadingConfigurationOnlinePayment || isLoadingPaymentMethod || isLoadingProduct || isLoadingStatement || isLoadingOrders || isLoadingInstance) console.log("lodaing")

  return (
    <div className="flex min-h-dvh w-full">
      <DialogComponent visible={initializeUser}
        title={
          () => "Czy chcesz założyć konto?"
        }
        content={
          () => "Twoje zmiany zostały zapisane, ale nie masz jeszcze założonego konta. Załóż je by nie stracić efektów swojej pracy."
        }
        actionsBar={
          () => {
            return (<>
              <Button variant={"filled"} content={"Załóż konto"}
                onClick={
                  () => navigate("/user")
                }
              />

              <Button variant={"empty"} content={"Kontynuuj bez zakładania konta"}
                onClick={
                  () => setInitializeUser(false)
                }
              />
            </>)
          }
        }
        onVisibleChange={
          (visible) => setInitializeUser(visible)
        }
      />

      <>
        <input type="checkbox" id="menu-toggle" name="menu-toggle" className="peer sr-only" />
        <label htmlFor="menu-toggle" aria-label="Toggle menu open" className="fixed left-0 top-0 z-[10000] h-auto p-4 xl:sr-only">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" className="z-[9999] h-8 w-8 text-indigo-700">
            <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
          </svg>
        </label>
        <div className="fixed inset-0 z-[9999] w-full max-w-[290px] grow flex-col gap-y-5 overflow-y-auto border-r border-gray-200 bg-white px-6 pt-16 peer-checked:hidden xl:static xl:flex xl:pt-0">
          <div className="sr-only xl:not-sr-only xl:flex xl:h-16 xl:shrink-0 xl:items-center">
            <img className="h-8 w-auto" src={Logo} alt="Paywallingi" />
          </div>
          <nav className="flex h-full flex-1 flex-col pt-16 xl:pt-0">
            <ul className="flex flex-col gap-y-2">
              <li>
                <Link to="Admin/paywalls" className="mb-4 flex items-center text-base font-semibold leading-7">
                  <ChevronLeftIcon className="h-6 w-6 shrink-0" />
                  <span>Wróć do listy paywall</span>
                </Link>
              </li>
              <li>
                <div>
                  <label htmlFor="" className="block text-sm font-medium leading-6 text-gray-900">
                    Twój przykładowy Paywall
                  </label>
                  <div className="group relative mt-2 rounded-md shadow-sm">
                    <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-2">
                      <span className="text-gray-500 sm:text-sm">
                        <UsersIcon className="h-4 w-4 shrink-0" />
                      </span>
                    </div>
                    <input type="text" className="block w-full rounded-md border-0 py-1.5 pl-7 pr-[58px] text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      ref={defaultOrderInput} value={defaultOrder} readOnly={true}
                    />
                    <button className="absolute inset-y-0 right-0 flex items-center pr-3 text-gray-500 hover:text-indigo-700 sm:text-sm"
                      onClick={
                        () => onCopyDefaultOrderUrl()
                      }
                    >
                      Kopiuj
                    </button>
                  </div>
                </div>
              </li>
              <li>
                <AccordionWrapper title="Opis" icon={<HomeIcon className="h-6 w-6 shrink-0" />}>
                  <PaywallEditFormComponent
                    onSuccessSaved={
                      () => onSuccessSaved()
                    }
                  />
                </AccordionWrapper>
              </li>
              <li>
                <AccordionWrapper title="Nazwa domeny" icon={<GlobeAltIcon className="h-6 w-6 shrink-0" />}>
                  <InstanceEditFormComponent
                    onSuccessSaved={
                      () => onSuccessSaved()
                    }
                  />
                </AccordionWrapper>
              </li>
              <li>
                <AccordionWrapper title="Integracja płatności" icon={<ShareIcon className="h-6 w-6 shrink-0" />}>
                  <ConfigurationOnlinePaymentEditFormComponent
                    onSuccessSaved={
                      () => onSuccessSaved()
                    }
                  />
                </AccordionWrapper>
              </li>
              <li>
                <AccordionWrapper title="Integracja faktur" icon={<ShareIcon className="h-6 w-6 shrink-0" />}>
                  <ConfigurationInvoiceEditForm
                    onSuccessSaved={
                      () => onSuccessSaved()
                    }
                  />
                </AccordionWrapper>
              </li>
              <li>
                <AccordionWrapper title="Produkt" icon={<TagIcon className="h-6 w-6 shrink-0" />}>
                  <ProductEditFormComponent
                    onSuccessSaved={
                      () => onSuccessSaved()
                    }
                  />
                </AccordionWrapper>
              </li>
              <li>
                <AccordionWrapper title="Metoda płatności" icon={<CurrencyEuroIcon className="h-6 w-6 shrink-0" />}>
                  <PaymentMethodEditFormComponent
                    onSuccessSaved={
                      () => onSuccessSaved()
                    }
                  />
                </AccordionWrapper>
              </li>
              <li>
                <AccordionWrapper title="Regulamin" icon={<DocumentIcon className="h-6 w-6 shrink-0" />}>
                  <StatementEditFormComponent
                    onSuccessSaved={
                      () => onSuccessSaved()
                    }
                  />
                </AccordionWrapper>
              </li>
              <li>
                <AccordionWrapper title="Lista zamówień" icon={<Bars4Icon className="h-6 w-6 shrink-0" />} handleClick={() => setIsOrdersOpen(!isOrdersOpen)} />
              </li>
              <li className="pt-2">
                <button className="flex w-full items-center gap-x-2 rounded-md bg-red-600 px-1 py-2 text-start text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600"
                  onClick={
                    () => {
                      if (window.confirm("Czy chcesz usunąć Twój Paywall?")) {
                        onDeletePaywall();
                      }
                    }
                  }
                >
                  <TrashIcon className="h-6 w-6 shrink-0" /> Usuń paywall
                </button>
              </li>
            </ul>
            <Link to={"/user"} className="mt-auto flex w-full items-center gap-x-4 self-start  py-3 text-sm font-semibold leading-6 text-gray-900 hover:bg-gray-50">
              <UserCircleIcon className="h-8 w-8 rounded-full bg-gray-50" />
              {/* <span className="sr-only">Your profile</span> */}
              <span aria-hidden="true">Użytkownik</span>
            </Link>
          </nav>
        </div>
      </>

      <div className="relative flex h-auto min-h-dvh w-full flex-col lg:flex-row-reverse">
        {isOrdersOpen ? (
          <OrdersComponent />
        ) : (<>
          <section className="relative flex h-full w-full flex-col items-center py-15 xs:px-11.25 md:px-43.5 md:py-22.5 lg:h-auto lg:items-start lg:px-11.25 xl:px-15">
            <img src={Background} alt="" className="absolute left-0 top-0 -z-[1] h-full w-full bg-cover" />
            <div className="flex w-full max-w-[420px] flex-col gap-y-7.5 px-7.5 xs:px-0 lg:max-w-[390px] xl:max-w-[360px]">
              <div className="flex flex-col gap-y-7.5 xs:min-h-[145px] xs:flex-row xs:items-center xs:gap-x-7.5">
                {paywall.paywallLogoFileId ? (
                  <>
                    <img className="aspect-square h-auto w-full shrink-0 overflow-hidden rounded-1.25 object-contain xs:h-[145px] xs:w-[145px] xl:h-full" src={paywallLogoFileContentGet} alt="" />
                  </>
                ) : null}
                <h1 className="text-3xl font-bold xxs:text-4xl xs:text-4xl xs:leading-normal">{paywall.paywallName}</h1>
              </div>
              <p className="text-black/75">{paywall.paywallDescription}</p>
              <div className="flex flex-col divide-y-2">
                <div className="flex flex-col gap-y-5 py-3.75 xs:flex-row xs:justify-between">
                  <p>Opłacasz:</p>
                  <div className="flex gap-x-3.75">
                    <p className="font-bold">F 001/2023</p>
                    <span className="flex items-center rounded-3.25 bg-[#FCC01180] px-2.5 py-1.25 font-semibold leading-4">Pobierz</span>
                  </div>
                </div>
                <div className="flex flex-col gap-y-5 py-3.75 xs:flex-row xs:justify-between">
                  <p>Do zapłaty:</p>
                  <p className="font-bold">615,00 zł</p>
                </div>
                <div className="flex flex-col gap-y-5 py-3.75 xs:flex-row xs:justify-between">
                  <p>Nabywca:</p>
                  <p className="font-bold">Nabywca SP. Z O.O.</p>
                </div>
              </div>
            </div>
          </section>
          <section className="shadow-r-custom lg:shadow-r flex w-full flex-col items-center px-7.5 py-15 xs:px-11.25 md:px-[174px] md:py-22.5 lg:max-w-[480px] lg:px-11.25 lg:shadow-custom xl:px-15">
            <fieldset className="flex max-w-[420px] flex-col gap-y-7.5 lg:max-w-[390px] xl:max-w-[360px]">
              <div className="flex flex-col gap-y-3.75">
                <h2 className="font-bold">Podaj swoje dane</h2>
                <p className="text-black/75">Przetwarzamy je aby poprawnie i bezpieczenie obsłużyć Twoją transakcję.</p>
              </div>
              <div className="flex flex-col gap-y-2.5">
                <label htmlFor="name">Imię:</label>
                <input type="text" id="name" placeholder="Imię" readOnly={true} className={clsx("rounded-1.25 border px-3.75 py-3", false ? "border-[#DC2626]" : "border-black/25")} />
              </div>
              <div className="flex flex-col gap-y-2.5">
                <label htmlFor="surname">Nazwisko:</label>
                <input type="text" id="surname" placeholder="Nazwisko" readOnly={true} className={clsx("rounded-1.25 border px-3.75 py-3", false ? "border-[#DC2626]" : "border-black/25")} />
              </div>
              <p className="rounded-1.25 border border-black/25 p-3.75 text-center text-xs leading-5 text-black/75">
                Wykonując płatność akceptuję{" "}
                <a href={statementOrderRulesDocumentFileContentGet} className="underline">
                  regulamin
                </a>{" "}
                oraz{" "}
                <a href={statementPrivacyPolicyDocumentFileContentGet} className="underline">
                  politykę prywatności
                </a>
                . Przyjmuję do wiadomości, że obie zgody mogę wycofać w każdej chwili.
              </p>
              <span className="block rounded-6.25 bg-[#FCC011] py-3.75 text-xl text-center font-semibold leading-5 xxs:px-18.75">
                Płacę 615,00 zł
              </span>
              <ul className="flex flex-col gap-y-3.75 text-xs leading-3">
                <li className="flex items-center gap-x-3.75">
                  {/* <Rectangle /> */}
                  <p>
                    <span className="font-bold">Bezpieczne połączenie</span> SSL
                  </p>
                </li>
                <li className="flex items-center gap-x-3.75">
                  {/* <Rectangle /> */}
                  <p>
                    Płatność obsługiwana przez <span className="font-bold">Planet Pay</span>
                  </p>
                </li>
                <li className="flex items-center gap-x-3.75">
                  {/* <Rectangle /> */}
                  <p>
                    Płatność kartą <span className="font-bold">VISA</span>
                  </p>
                </li>
                <li className="flex items-center gap-x-3.75">
                  {/* <Rectangle /> */}
                  <p>
                    Płatność kartą <span className="font-bold">MasterCard</span>
                  </p>
                </li>
                <li className="flex items-center gap-x-3.75">
                  {/* <Rectangle /> */}
                  <p>
                    Integratorem płatności jest <span className="font-bold">Paywallingi.pl</span>
                  </p>
                </li>
              </ul>
            </fieldset>
          </section>
        </>)}
      </div>
    </div>
  )
}
