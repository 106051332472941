import { createSlice } from "@reduxjs/toolkit"
import { getInstance } from "services/api.instance"
import { getPaywall } from "services/api.paywall"

interface InstanceState {
  isLoading: boolean
  _links: any
}

const initialState: InstanceState = {
  isLoading: false,
  _links: {},
}

const instanceSlice = createSlice({
    name: "instance",

    initialState: initialState,

    reducers: {
      //
    },

    extraReducers: (builder) =>
      builder
        //
        // Paywall
        //
        .addCase(getPaywall.fulfilled, (state, action) => {
            if (
              ! action.payload
            ) {
              return
            }

            const { instanceModify, instanceGet } = action.payload._links

            state._links = {
              ...state._links,
              instanceModify,
              instanceGet
            }
          }
        )

        //
        // Instance
        //
        .addCase(getInstance.pending, (state) => {
            state.isLoading = true
          }
        )
  
        .addCase(getInstance.fulfilled, (state) => {
            state.isLoading = false
          }
        )
  
        .addCase(getInstance.rejected, (state) => {
            state.isLoading = false
          }
        )
  }
)

export const { reducer: instanceReducer } = instanceSlice
