import { Button, InputWithLabel } from "components"
import { useEffect } from "react"
import { useForm } from "react-hook-form"
import toast from "react-hot-toast"
import { useNavigate } from "react-router-dom"
import { addInstance, getInstance } from "services/api.instance"
import { useAppDispatch, useAppSelector } from "store"

export const InstanceEditFormComponent = (
  { onSuccessSaved }: { onSuccessSaved?: () => void }
) => {
  const navigate = useNavigate()

  const dispatch = useAppDispatch()

  const serverIpAddress = useAppSelector((x) => x.server.serverIpAddress)

  const {
    register,
    handleSubmit,
    setValue,
    formState: {
      errors
    }
  } = useForm<{
      instanceNetworkNameAddress: string | null
    }
  >()

  useEffect(
    () => {
      dispatch(
        getInstance()
      )

      .unwrap()

      .then(
        (x) => setValue("instanceNetworkNameAddress", x.instance.instanceNetworkNameAddress)
      )
    },
      []
  )

  /**
   * Zapisuje instancję
   * @param data Formularz
   */
  const onInstanceSave = (
    data: { instanceNetworkNameAddress: string | null }
  ) => {
    dispatch(
      addInstance({
          instance: { instanceNetworkNameAddress: data.instanceNetworkNameAddress }
        }
      )
    )

    .unwrap()

    .then(
      () => {
        if (onSuccessSaved) {
          onSuccessSaved();
        }

        toast.success("Instancja zapisana.")
      }
    )

    .catch(
      (x) => toast.error(x)
    )
  }

  /**
   * Otwiera Paywalls
   */
  const onPaywallsOpen = () => {
    navigate("/paywalls")
  }

  return (
    <>
      <form
        className="flex
          flex-col
          gap-y-2"
        onSubmit={
          handleSubmit(
            (x) => onInstanceSave(x)
          )
        }
      >
        <div>
          <h2 className="text-base font-semibold leading-7 text-gray-900">
            Ustaw nazwę domeny
          </h2>

          <p className="mt-1 text-sm leading-6 text-gray-600">
            Dla bezpieczeństwa Twoich klientów Paywall powinien być udostępniony pod Twoją domeną. W ten sposób Twoi klienci będą mieli pewność, że wykonują płatność do właściwego odbiorcy.
          </p>

          <p className="mt-1 text-sm leading-6 text-gray-600">
            W celu wydelegowania domeny ustaw jej nazwę i wydeleguj rekord A na odpowiedni adres IP:
          </p>

          <dl
            className="flex
              justify-between
              gap-x-4
              py-3"
          >
            <dt
              className="rounded-md py-1 px-2 text-xs font-medium ring-1 ring-inset text-black bg-black-50 ring-black/10"
            >
              rekord A
            </dt>

            <dd className="font-medium text-black">
              {serverIpAddress?.serverIpAddress}
            </dd>
          </dl>
        </div>

        <InputWithLabel id="instanceNetworkNameAddress"
          label="Nazwa domeny:"
          labelFor="productName"
          isError={!!errors.instanceNetworkNameAddress?.message}
          errorMessage={errors.instanceNetworkNameAddress?.message}
          {...register("instanceNetworkNameAddress", {
              required: "Uzupełnij domenę.", maxLength: { value: 4000, message: "Uzupełnij domenę maksymalnie 4000 znakami." }
            }
          )}
        />

        <div className="mt-4 flex items-center justify-end gap-x-6">
          <Button
            variant="empty"
            content="Anuluj"
            onClick={
              () => onPaywallsOpen()
            }
          />

          <Button variant="filled" content="Zapisz" />
        </div>
      </form>
    </>
  )
}
