import { useForm } from "react-hook-form"
import { addUser } from "services/api.user"
import { useAppDispatch } from "store"
import type { UserRequest } from "types"
import { Button, InputWithLabel } from "components"
import toast from "react-hot-toast"

export const UserEditFormComponent = () => {
  const dispatch = useAppDispatch()

  const {
    register,
    handleSubmit,
    formState: {
      errors
    }
  } = useForm<{
      user: UserRequest
    }
  >()

  /**
   * Dodaje użytkownika
   * @param data Formularz
   */
  const onUserAdd = (
    data: { user: UserRequest }
  ) => {
    dispatch(
      addUser({
          user: data.user
        }
      )
    )

    .unwrap()

    .then(
      () => toast.success("Użytkownik utworzony.")
    )

    .catch(
      (x) => toast.error(x)
    )
  }

  return (
    <form
      className="flex flex-col gap-y-6"
      onSubmit={
        handleSubmit(
          (x) => onUserAdd(x)
        )
      }
    >
      <div
        className="flex
          max-w-2xl
          flex-1
          flex-col
          gap-y-6
          sm:w-full"
      >
        <InputWithLabel
          type={"email"}
          label="Adres e-mail:"
          labelFor={"user.userContactEmailAddress"}
          isError={!!errors.user?.userContactEmailAddress}
          errorMessage={errors.user?.userContactEmailAddress?.message}
          {...register("user.userContactEmailAddress", {
              required: "Uzupełnij adres e-mail.", maxLength: { value: 4000, message: "Uzupełnij maksymalnie 4000 znakami." } }
            )
          }
        />

        <InputWithLabel
          type={"password"}
          label="Uzupełnij hasło"
          labelFor={"user.userPassword"}
          isError={!!errors.user?.userPassword}
          errorMessage={errors.user?.userPassword?.message}
          {...register("user.userPassword", {
              required: "Uzupełnij hasło.", maxLength: { value: 4000, message: "Uzupełnij maksymalnie 4000 znakami." } }
            )
          }
        />
      </div>

      <div
        className="flex
          w-full
          justify-end
          border-t
          border-gray-900/10
          pt-4"
      >
        <Button variant={"filled"} type={"submit"} content="Utwórz konto" />
      </div>
    </form>
  )
}
