import { createSlice } from "@reduxjs/toolkit"
import { getPaywall } from "services/api.paywall"

interface FileState {
  _links: any
}

const initialState: FileState = {
  _links: {}
}

const fileSlice = createSlice({
  name: "file",

  initialState: initialState,

  reducers: {
    //
  },

  extraReducers: (builder) =>
    builder
      //
      // Paywall
      //
      .addCase(getPaywall.fulfilled, (state, action) => {
        if (!action.payload) {
          return
        }

        const { fileAdd } = action.payload._links

        state._links = { ...state._links, fileAdd }
      })
})

export const { reducer: fileReducer } = fileSlice
