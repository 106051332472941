import { createAsyncThunk } from "@reduxjs/toolkit"
import { StatementRequest, StatementResponse } from "types"
import { api } from "./api"
import { resolveMessage } from "./api.error"

export interface StatementGetResponse {
  statement: StatementResponse
  _links: any
}

export const modifyStatement = createAsyncThunk("app/modifyStatement", (
    request: { statement: StatementRequest }, { getState, rejectWithValue }
  ) => {
    const state: any = getState()

    const { statementModify } = state.statement._links

    return api.put(statementModify, request)

    .catch(
      (x) => {
        let errors = ""

        if (
          "errors" in x.response.data
        ) {
          errors = resolveMessage(
            x.response.data.errors
          )
        }

        return rejectWithValue(errors)
      }
    )
  }
)

export const getStatement = createAsyncThunk("app/getStatement", (
    _1, { getState }
  ) => {
    const state: any = getState()

    const { statementGet } = state.statement._links

    return api.get<StatementGetResponse>(statementGet)

    .then(
      (x) => x.data
    )
  }
)
