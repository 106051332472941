import { useForm } from "react-hook-form"
import { useNavigate } from "react-router-dom"
import { addFile } from "services/api.file"
import { modifyPaywall, refreshPaywall } from "services/api.paywall"
import { useAppDispatch, useAppSelector } from "store"
import { PhotoIcon } from "@heroicons/react/16/solid"
import { Button, InputWithLabel, TextareaWithLabel } from "components"
import toast from "react-hot-toast"
import clsx from "clsx"
import { useEffect } from "react"

export const PaywallEditFormComponent = (
  { onSuccessSaved }: { onSuccessSaved?: () => void }
) => {
  const navigate = useNavigate()

  const dispatch = useAppDispatch()

  const { paywall } = useAppSelector((state) => state.paywall)

  const { paywallLogoFileContentGet } = useAppSelector((state) => state.paywall._links)

  const {
    setValue,
    register,
    handleSubmit,
    formState: {
      errors
    }
  } = useForm<{
    paywallName: string
    paywallDescription?: string
    paywallLogoFileId?: string
    files: any[]
  }>()

  useEffect(() => {
    if (paywall) {
      setValue("paywallName", paywall.paywallName)

      setValue("paywallDescription", paywall.paywallDescription)

      setValue("paywallLogoFileId", paywall.paywallLogoFileId)
    }
  }, [paywall])

  /**
   * Zapisuje Paywall
   * @param data Formularz
   */
  const onPaywallSave = (
    data: { paywallName: string; paywallDescription?: string; paywallLogoFileId?: string; files: any[] }
  ) => {
    const formData = new FormData()

    if (
         data.files
      && data.files.length
    ) {
      for (
        let i = 0; i < data.files.length; i++
      ) {
        formData.append(
          "file", data.files[i]
        )
      }
    }

    return dispatch(
      addFile(formData)
    )

    .unwrap()

    .then(
      (x) => {
        if (x.file) {
          data.paywallLogoFileId = x.file.fileId
        }

        return dispatch(
          modifyPaywall(
            { paywall: { paywallName: data.paywallName, paywallDescription: data.paywallDescription, paywallLogoFileId: data.paywallLogoFileId } }
          )
        )
        
        .unwrap()
      }
    )

    .then(
      () => {
        if (onSuccessSaved) {
          onSuccessSaved();
        }

        toast.success("Paywall zapisany.")

        return dispatch(
          refreshPaywall()
        )
        
        .unwrap()
      }
    )

    .catch(
      (x) => toast.error(x)
    )
  }

  /**
   * Otwiera Paywalls
   */
  const onPaywallsOpen = () => {
    navigate("/paywalls")
  }

  return (
    <form
      className="flex
        flex-col
        gap-y-2"
      onSubmit={
        handleSubmit(
          (x) =>  onPaywallSave(x)
        )
      }
    >
      <InputWithLabel
        type="text"
        id="paywall.paywallName"
        label="Nazwa formularza:"
        labelFor="paywall.paywallName"
        isError={!!errors.paywallName}
        errorMessage={errors.paywallName?.message}
        {...register("paywallName", {
          required: "Uzupełnij nazwę.",
          maxLength: { value: 4000, message: "Nazwa nie może być dłuższa niż 4000 znaków." }
        })}
      />
      <TextareaWithLabel
        id="paywall.paywallDescription"
        label="Opis"
        labelFor="paywall.paywallDescription"
        defaultValue=""
        rows={4}
        isError={!!errors.paywallDescription}
        errorMessage={errors.paywallDescription?.message}
        {...register("paywallDescription", {
          maxLength: { value: 4000, message: "Opis nie może być dłuższy niż 4000 znaków." }
        })}
      />
      <div className="col-span-full">
        <label htmlFor="cover-photo" className="block text-sm font-medium leading-6 text-gray-900">
          Logo
        </label>
        <div className={clsx("relative mt-2 flex justify-center rounded-lg border border-dashed border-gray-900/25", paywall?.paywallLogoFileId ? "p-1" : "px-6 py-10")}>
          {paywall?.paywallLogoFileId ? (
            <>
              <img src={paywallLogoFileContentGet} className="object-cover opacity-50" alt="" />
              <div className="absolute bottom-2 left-0 right-0 mx-auto flex text-sm leading-6 text-gray-600">
                <label htmlFor="files" className="mx-auto cursor-pointer rounded-md bg-white px-1 font-semibold text-indigo-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-600 focus-within:ring-offset-2 hover:text-indigo-500">
                  <span>Upload another file</span>
                  <input id="files" {...register("files")} name="files" type="file" className="sr-only" />
                </label>
              </div>
            </>
          ) : (
            <div className="text-center">
              <PhotoIcon className="mx-auto h-12 w-12 text-gray-300" aria-hidden="true" />
              <div className="mt-4 flex text-sm leading-6 text-gray-600">
                <label htmlFor="files" className="relative cursor-pointer rounded-md bg-white font-semibold text-indigo-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-600 focus-within:ring-offset-2 hover:text-indigo-500">
                  <span>Upload a file</span>
                  <input id="files" {...register("files")} name="files" type="file" className="sr-only" />
                </label>
                <p className="pl-1">or drag and drop</p>
              </div>
              <p className="text-xs leading-5 text-gray-600">PNG, JPG, GIF up to 10MB</p>
            </div>
          )}
        </div>
      </div>
      <div className="mt-4 flex items-center justify-end gap-x-6">
        <Button variant="empty" content="Anuluj"
          onClick={
            () => onPaywallsOpen()
          }
        />

        <Button type="submit" variant="filled" content="Zapisz" />
      </div>
    </form>
  )
}
