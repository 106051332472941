import { createAsyncThunk } from "@reduxjs/toolkit"
import { FileBaseResponse } from "types"
import { api } from "./api"
import { resolveMessage } from "./api.error"

export interface FileAddresponse {
  file: FileBaseResponse
  _links: any
}

export const addFile = createAsyncThunk("app/addFile", (
    form: FormData, { getState, rejectWithValue }
  ) => {
    const state: any = getState()

    const { fileAdd } = state.file._links

    return api.post<FileAddresponse>(fileAdd, form)

    .then(
      (x) => x.data
    )

    .catch(
      (x) => {
        let errors = ""

        if (
          "errors" in x.response.data
        ) {
          errors = resolveMessage(
            x.response.data.errors
          )
        }

        return rejectWithValue(errors)
      }
    )
  }
)
