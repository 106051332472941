import { createAsyncThunk } from "@reduxjs/toolkit";
import { ServerIpAddressResponse } from "types";
import { api } from "./api";

export interface ServerIpAddressGetResponse {
  serverIpAddress: ServerIpAddressResponse
}

export const getServerIpAddress = createAsyncThunk("api/getServerIpAddress", (
    _1, { getState }
  ) => {
    const state: any = getState();

    const { serverIpAddressGet } = state.server._links;

    return api.get<ServerIpAddressGetResponse>(serverIpAddressGet)

    .then(
      (x) => x.data
    );
  }
)
